/**
 *  index.js
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 20/12/2018.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import React from "react";
import ReactDOM from "react-dom";

import { Provider } from 'react-redux';

import { PersistGate } from 'redux-persist/es/integration/react';

import { store, persistor } from './store';
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/scss/paper-dashboard.scss";
import "bootstrap/dist/css/bootstrap.css";

import indexRoutes from "routes/index.jsx";

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <HashRouter>
        <Switch>
          {indexRoutes.map((prop, key) => {
            if (prop.redirect)
            return <Redirect from={prop.path} to={prop.to} key={key} />;
            return <Route path={prop.path} component={prop.component} key={key} />;
          })}
        </Switch>
      </HashRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
