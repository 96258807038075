/**
 *  actions/demandsDelivery.js
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 09/01/2019.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import * as actionTypes from './actionTypes';

export const savePendingDelivery = value => ({
    type: actionTypes.SAVE_PENDING_DELIVERY,
    pendingDelivery: value
});

export const clearDemands = value => ({
    type: actionTypes.CLEAR_DEMANDS,
    pendingDelivery: []
});