/**
 *  utils/index.js
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 17/01/2019.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

var firebase = require("../firebase");

/**
 * Metodo para converter timestamp para Date
 */
export function dateFormatter(value) {
  let formattedDate = new Date(value).toLocaleDateString();
  return formattedDate;
}

/**
 * Metodo para formatar centavos em reais
 */
export function currencyFormatter(cents) {
  if (cents === null || cents === undefined){
    return 0;
  } else {

    var tmp = cents + '';

    if (tmp.length == 0 || tmp == 0){
      tmp = '0,00';
    } else if (tmp.length == 1){
      tmp = '0,0' + tmp
    } else if (tmp.length == 2){
      tmp = '0,' + tmp
    } else {
   
      tmp = tmp.replace(/([0-9]{2})$/g, ",$1");

      if( tmp.length > 6 ) {
        tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
      }
    }

    return "R$ " + tmp;
  }
}

/**
 * Metodo verificar autenticação do usuário
 */
export function verifyUser() {
  firebase.auth.onAuthStateChanged((user) => {
    if (!user){
      window.location.replace("/");
    }
  });
}

/**
 * Metodo para traduzir mensagens do firebase p/ serem exibidas ao usuário
 */
export function portugueseMessage(message) {
  if(message == 'The email address is badly formatted.'){
    return message = 'Preencha os dados corretamente!'
  }
  if(message == 'The password is invalid or the user does not have a password.'){
    return message = 'Oops! Dados incorretos. Tente Novamente!'
  }
  if(message == 'There is no user record corresponding to this identifier. The user may have been deleted.'){
    return message = 'Este e-mail não está vinculado a nenhuma conta!'
  }
  if(message == 'Too many unsuccessful login attempts. Please include reCaptcha verification or try again later'){
    return message = 'Muitas tentativas de login malsucedidas. Tente novamente mais tarde!'
  }
  if(message == 'We have blocked all requests from this device due to unusual activity. Try again later.'){
    return message = 'Bloqueamos todos os pedidos deste dispositivo devido a atividade incomum. Tente mais tarde.'
  }
}

/**
 * Metodo para formartar data no filtro da tabela
 */
export function stringfy(epoch){
  var date = new Date (epoch);
  var year = date.getFullYear().toString();
  var month = (date.getMonth() + 1).toString();
  var day = date.getDate().toString();

  if (month.length < 2){
      month = "0" + month;
  }
  if (day.length < 2){
      day = "0" + day;
  }

  var string = year + "-" + month + "-" + day;

  return string;
}

/**
 * Metodo para buscar uma data atraves da string
 */
export function getEpoch(date){
  let d = date.split('-');
  let year = parseInt(d[0]);
  let month = parseInt(d[1])-1;
  let day = parseInt(d[2]);

  let newDate = new Date( year, month, day);
  let newInitialEpoch = newDate.getTime();

  return newInitialEpoch;
}


export function cnpjMask(value) {
  let newValue = value.replace(/\D/g,"");

  newValue = newValue.replace(/^(\d{2})(\d)/,"$1.$2"); // Ponto entre 2º e 3º
  newValue = newValue.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3"); // Ponto entre 5º e 6º
  newValue = newValue.replace(/\.(\d{3})(\d)/,".$1/$2"); // Barra entre 8º e 9º
  newValue = newValue.replace(/(\d{4})(\d)/,"$1-$2"); // Hifen entre 12º e 13º
 
  return newValue;
}

export function cepMask(value) {
  let newValue = value.replace(/\D/g,"");

  newValue = newValue.replace(/^(\d{5})(\d)/,"$1-$2");
  return newValue;
}

export function phoneMask(value) {
  let newValue = value.replace(/\D/g,"");

  newValue = newValue.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
  newValue = !newValue [2] ? newValue [1] : '(' + newValue [1] + ') ' + newValue [2] + (newValue [3] ? '-' + newValue [3] : '');

  return newValue;
}

export function removeMask(value) {
  let newValue = value.replace(/\D/g,"");
  return newValue;
}