/**
 *  EstimateTab.jsx
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 09/01/2019.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import React, { Component } from "react";

import { Card, CardBody, Row, Col, TabContent,  TabPane, Nav,  NavItem, NavLink } from "reactstrap";

import EstimateOpened from "./Tables/EstimateOpened";
import EstimateWaiting from "./Tables/EstimateWaiting";
import EstimateHistoric from "./Tables/EstimateHistoric";

import { HeaderFilter } from "./Tables/HeaderFilter";

import { connect } from 'react-redux';
import classnames from 'classnames';

require('../../assets/css/style.css');

var utils = require("../../utils/index.js");
var firebase = require("../../firebase");

class EstimateTab extends Component {

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
      initialDate: null,
      finalDate: null,
      historicData: []
    };
  }

  componentWillMount() {
    this.getDate();
  }

  componentDidMount(){
    document.title =  this.props.storeInfo.name + " | Orçamentos";
    utils.verifyUser(); // Verifica credenciais do usuário
    this.verifyStore(); // Verifica se há uma loja selecionada
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  verifyStore = () => {
    const { history, storeInfo} = this.props;

    if (storeInfo === undefined || storeInfo === ""  || storeInfo === null){
      history.push('/stores');
    } 
  }

  reload = () => {
    this.setState({ historicData: [] });
    this.loadData(this.state.initialDate, this.state.finalDate);
  }

  getDate = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    const initialDate = new Date(currentYear, currentMonth, 1);
    const finalDate = new Date(currentYear, currentMonth + 1, 1);
    const initialEpoch = initialDate.getTime();
    const finalEpoch = finalDate.getTime() - 1;

    this.setState({
      initialDate: initialEpoch,
      finalDate: finalEpoch
    });

    this.loadData(initialEpoch, finalEpoch);
  }

  filter = (initialEpoch, finalEpoch) => {
    this.setState({
      initialDate: initialEpoch,
      finalDate: finalEpoch
    });

    this.clearData();
    this.loadData(initialEpoch, finalEpoch);
  }

  clearData = () => {
    this.setState({
      historicData: []
    });
  }

  loadData = (initialDate, finalDate) => {
    let historicRows = [];
    
    firebase.database.ref('suppliers/' + this.props.storeInfo.key + '/orders').on('value', function(snapshot) {
      const snaps = snapshot.val();
      
      for(const key in snaps) {
        const values = snaps[key];

        if (values.status != 1 && values.status != 2) {
          var orders = new Object();

          orders["key"] = key;
          orders["actions"] = key;
          orders["clientName"] = values.clientName;
          orders["createdAt"] = values.createdAt;
          orders["deliveryMode"] = values.deliveryMode;
          orders["projectName"] = values.projectName;
          orders["projectType"] = values.projectType;
          orders["status"] = values.status;
          orders["dateEnd"] = values.dateEnd;
          orders["dateDelivery"] = values.dateDelivery;
          orders["orderCode"] = values.orderCode;
          orders["offer"] = values.offer ? values.offer : [];
          orders["orderItems"] = values.orderItems;

          if (values.dateEnd >= initialDate && values.dateEnd <= finalDate){
            historicRows.push(orders);
          }
        }
      }
    });

    this.setState({ historicData: historicRows })
  }

  render() {
    const { initialDate, finalDate } = this.state;
    return (
        <div className="content">
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                <div>
                    <Nav tabs>
                      
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '1' })}
                          onClick={() => { this.toggle('1'); }}
                        >
                          Em Aberto
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '2' })}
                          onClick={() => { this.toggle('2'); }}
                        >
                          Aguardando Cliente
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '3' })}
                          onClick={() => { this.toggle('3'); }}
                        >
                          Histórico
                        </NavLink>
                      </NavItem>
                      
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                      
                      <TabPane tabId="1">
                        <Row>
                          <Col sm="12">
                            <br></br>
                            <EstimateOpened />
                          </Col>
                        </Row>
                      </TabPane>

                      <TabPane tabId="2">
                        <Row>
                          <Col sm="12">
                            <br></br>
                            <EstimateWaiting />
                          </Col>
                        </Row>
                      </TabPane>

                      <TabPane tabId="3">
                        <Row>
                          <Col sm="12">
                            <HeaderFilter reload={this.reload} filter={this.filter} initialEpoch={initialDate} finalEpoch={finalDate} />
                          </Col>
                        </Row>
                        
                        <Row>
                          <Col sm="12">
                            <EstimateHistoric data={this.state.historicData} />
                          </Col>
                        </Row>
                      </TabPane>

                    </TabContent>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
    );
  }
}
const mapStateToProps = store => ({
  storeInfo: store.storeInfo.storeInfo
});

export default connect(mapStateToProps)(EstimateTab);
