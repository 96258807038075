/**
 *  actions/notifications.js
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 10/01/2019.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import * as actionTypes from './actionTypes';

export const saveNotifications = value => ({
    type: actionTypes.SAVE_NOTIFICATIONS,
    notifications: value
});

export const clearNotifications = value => ({
    type: actionTypes.CLEAR_NOTIFICATIONS,
    notifications: ""
});