/**
 *  EstimateOpened.jsx
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 06/01/2019.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import React, { Component } from "react";

import {
    DataTypeProvider,
    SortingState,
    IntegratedSorting,
    PagingState,
    IntegratedPaging,
    SearchState, 
    IntegratedFiltering,
} from '@devexpress/dx-react-grid';

import { 
    Grid, 
    Table, 
    TableHeaderRow,
    PagingPanel,
    Toolbar,
    SearchPanel,
} from '@devexpress/dx-react-grid-bootstrap4';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { saveOrdersOpened, clearOrders } from '../../../actions/ordersOpened';
import ActionsFormatter from "../ButtonActions/ActionsButtons";

require('../../../assets/css/style.css')

var firebase = require("../../../firebase");
var utils = require("../../../utils/index.js");

const ActionsButtons = (props) => { 
    return (
        <DataTypeProvider
        formatterComponent={ActionsFormatter}
        {...props}
        />
    )
};

class EstimateOpened extends Component {

    constructor(props) {
        super(props);

        this.state = {
            columns: [
                { name: 'orderCode', title: '#Código' },
                { name: 'clientName', title: 'Cliente' },
                { name: 'clientPhone', title: 'Telefone' },
                { name: 'clientEmail', title: 'E-mail' },
                { name: 'deliveryMode', title: 'Tipo de Entrega', 
                    getCellValue: row => (
                        row.deliveryMode == 1 ? <span className="badge badge-warning p-1">Retirar na loja</span> : <span className="badge badge-primary p-1">Entregar na Obra</span>
                    )
                },
                { name: 'period', title: 'Orçar até', 
                    getCellValue: row => (utils.dateFormatter(row.dateEnd))
                },
                { name: 'dateDelivery', title: 'Entregar até', 
                    getCellValue: row => (utils.dateFormatter(row.dateDelivery))
                },
                { name: 'actions', title: 'Ações' },
            ],
            actionColumn: ['actions'],
            searchValue: '',
        };

        this.changeSearchValue = value => this.setState({ searchValue: value });
    }

    componentDidMount() {
        const { saveOrdersOpened, storeInfo} = this.props;
        firebase.database.ref('suppliers/' + storeInfo.key + '/orders').on('value', function(snapshot) {
            const snaps = snapshot.val();
            var all = [];

            for(const key in snaps) {
                const values = snaps[key];

                if (values.status == 1) {
                    var ordersOpened = new Object();
                    ordersOpened["key"] = key;
                    ordersOpened["actions"] = key;
                    ordersOpened["clientName"] = values.clientName;
                    ordersOpened["clientPhone"] = values.clientPhone;
                    ordersOpened["clientEmail"] = values.clientEmail;
                    ordersOpened["createdAt"] = values.createdAt;
                    ordersOpened["deliveryMode"] = values.deliveryMode;
                    ordersOpened["projectName"] = values.projectName;
                    ordersOpened["projectType"] = values.projectType;
                    ordersOpened["status"] = values.status;
                    ordersOpened["dateEnd"] = values.dateEnd;
                    ordersOpened["dateDelivery"] = values.dateDelivery;
                    ordersOpened["orderCode"] = values.orderCode;
                    ordersOpened["orderItems"] = values.orderItems;
                    ordersOpened["projectAddress"] = values.projectAddress;
                    ordersOpened["projectCity"] = values.projectCity;
                    ordersOpened["projectState"] = values.projectState;

                    all.push(ordersOpened);
                }
            }
            
            // Salva a lista de orçamentos em Aberto no Redux
            saveOrdersOpened(all);
        });
    }

    render() {
        const { ordersOpened } = this.props;
        const { columns, actionColumn, searchValue} = this.state;
        return (
            <Grid
                rows={ordersOpened}
                columns={columns}
            >

                <ActionsButtons
                    for={actionColumn}
                />
                
                <PagingState
                    defaultCurrentPage={0}
                    defaultPageSize={25}
                />
                <IntegratedPaging />
                <PagingPanel
                    pageSizes={[10, 25, 50, 0]}
                />

                <SortingState
                    defaultSorting={[{ columnName: 'dateEnd', direction: 'asc' }]}
                />
                <IntegratedSorting />

                <SearchState
                    value={searchValue}
                    onValueChange={this.changeSearchValue}
                />
                <IntegratedFiltering />
                <Toolbar />
                <SearchPanel/>

                <Table />
                <TableHeaderRow showSortingControls />
            </Grid>
        );
    }
}

const mapStateToProps = store => ({
    storeInfo: store.storeInfo.storeInfo,
    ordersOpened: store.ordersOpened.ordersOpened
});

const mapDispatchToProps = dispatch =>
    bindActionCreators({ saveOrdersOpened, clearOrders }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EstimateOpened);