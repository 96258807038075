/**
 *  Sidebar.jsx
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 17/12/2018.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Nav, Badge } from "reactstrap";
import PerfectScrollbar from "perfect-scrollbar";

import { connect } from 'react-redux';

require("../../assets/css/style.css");

var ps;

const badge = {
  marginLeft: '10px',
  backgroundColor: '#c37a4f'
};

class Sidebar extends Component {
  
  constructor(props) {
    super(props);
  }

  activeRoute = (routeName) => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  render() {
  
    return (
      <div className="sidebar" data-color={this.props.bgColor} data-active-color={this.props.activeColor}>
        <div className="logo">
          <a href="" className="logo-normal">
            <img src={require('../../assets/img/img_logo_branco.png')}></img>
          </a>
        </div>
        <div className="sidebar-wrapper" ref="sidebar">
          <Nav>
            {this.props.routes.map((prop, key) => {
              if (prop.redirect) return null;
              return (
                <li
                  className={
                    this.activeRoute(prop.path) +
                    (prop.pro ? "active-pro" : "")
                  }
                  key={key}
                >
                  <NavLink
                    to={prop.path}
                    className="nav-link"
                    activeClassName="active"
                  >
                    <i className={prop.icon} />
                    <p>{prop.name} {prop.badge ? <Badge style={badge}>{this.props.ordersOpened.length}</Badge> : ""}</p>
                  </NavLink>
                </li>
              );
            })}
          </Nav>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  ordersOpened: store.ordersOpened.ordersOpened
});

export default connect(mapStateToProps)(Sidebar);
