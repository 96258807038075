/**
 *  reducers/index.js
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 10/12/2018.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import { userInfo } from './userInfo';
import { storeInfo } from './storeInfo';
import { ordersOpened } from './ordersOpened';
import { ordersWaiting } from './ordersWaiting';
import { pendingPayment } from './demandsPayment';
import { pendingConfirm } from './demandsConfirm';
import { pendingDelivery } from './demandsDelivery';
import { notifications } from './notifications';

import { combineReducers } from 'redux';

export const Reducers = combineReducers({
  userInfo: userInfo,
  storeInfo: storeInfo,
  ordersOpened: ordersOpened,
  ordersWaiting: ordersWaiting,
  pendingPayment: pendingPayment,
  pendingConfirm: pendingConfirm,
  pendingDelivery: pendingDelivery,
  notifications: notifications
});
