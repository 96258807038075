/**
 *  actions/demandsPayment.js
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 08/01/2019.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import * as actionTypes from './actionTypes';

export const savePendingPayment = value => ({
    type: actionTypes.SAVE_PENDING_PAYMENT,
    pendingPayment: value
});

export const clearDemands = value => ({
    type: actionTypes.CLEAR_DEMANDS,
    pendingPayment: []
});