/**
 *  Subscribe.jsx
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 21/12/2018.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import React, { Component } from "react";

import { Card, CardHeader, CardBody, CardTitle, Button, Row, Col } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import FormInputs from "components/FormInputs/FormInputs.jsx";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { saveUserInfo, clearUserInfo } from '../../actions/userInfo';

require("../../assets/css/style.css");

var firebase = require("../../firebase");
var utils = require("../../utils/index.js");
var request = require('request');
const { GeoFire } = require('geofire');

class Subscribe extends Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            data: {},
        };
    }
    
    componentDidMount() {
        document.title = "Ohbra | Adicionar Estabelecimento";
    }

    onFieldChanged = (e) => {
        this.setState({data: {...this.state.data, [e.target.name]: e.target.value}})
    }

    cepMask = (e) => {
        e.target.value = utils.cepMask(e.target.value);
        let cep = e.target.value;
        
        if (cep.length === 9) {
            cep = utils.removeMask(cep);
            this.setState({ data: { cep: cep }}, () => {
                request({
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    url: "https://viacep.com.br/ws/" + cep + "/json/",
                    method: "GET"
                }, (error, response, body) => {
                    let res = JSON.parse(body);
                    this.setState({data: {...this.state.data, city: res.localidade, address: res.logradouro, UF: res.uf, district: res.bairro}});
                });
            });
        }
    }

    cnpjMask = (e) => {
        e.target.value = utils.cnpjMask(e.target.value);
        let cnpj = e.target.value;

        if (cnpj.length === 18) {
            cnpj = utils.removeMask(cnpj);
            this.setState({ data: {...this.state.data, CNPJs: cnpj }});
        }
    }

    setData = (e) => {
        e.preventDefault()
        const {data} = this.state;

        let step1 = this.validateStep1();
        let step2 = this.validateStep2();

        if (step1 && step2) {
            data['CNPJ'] = utils.removeMask(data.CNPJ);
            data['CEP'] = utils.removeMask(data.CEP);
            data['address'] = `${data.address}, ${data.number}`;
            data['admins'] = {[this.props.userInfo.key]: 1};
            data['statistics'] = {
                acceptedOrders: 0,
                finishedRequests: 0,
                revenues: 0
            };
            data['status'] = 0;

            delete data.number;

            let key = firebase.database.ref('suppliers').push().key;
            firebase.database.ref('suppliers/' + key).set(data).then(() => {
                firebase.database.ref('users/' + this.props.userInfo.key + '/suppliers/' + key).set(1).then(() => {
                    if (this.props.userInfo.suppliers) {
                        this.props.userInfo.suppliers[key] = 1;
                    } else {
                        this.props.userInfo['suppliers'] = {
                            [key]: 1
                        }
                    }
                    
                    this.props.saveUserInfo(this.props.userInfo);
                    this.setAddressForAPI(data, key);
                });
            });
        } else {
            this.notify('Preencha corretamente os campos obrigatórios.');
        }
    }

    setAddressForAPI = (data, key) => {
        let address = `${data.address},_${data.city}`;
        address = address.replace(" ", "_");
        address = address.replace(" ", "_");
        address = address.replace(" ", "_");

        request({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            url: `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyCV3bgOEX1eGBUOxAD2Oqyebk-xY2dDUqc`,
            method: "GET"
        }, (error, response, body) => {
            let res = JSON.parse(body);
            let result = res.results[0];
            let location = result.geometry.location;

            this.setLocationGeoFire(location, key);
        });
    }

    setLocationGeoFire = (location, key) => {
        let locationRef = firebase.database.ref('supplierLocation');
        let geoFire = new GeoFire(locationRef);

        let newLocation = [location.lat, location.lng];
        geoFire.set(key, newLocation);

        this.props.history.push('/stores');
    }

    validateStep1 = () => {
        const {data} = this.state;

        if (data.CNPJ !== undefined && data.name !== undefined && data.email !== undefined && data.CEP !== undefined && data.number !== undefined) {
            if (data.CNPJ !== "" && data.name !== "" && data.email !== "" && data.CEP !== "" && data.number !== "") {
                if (data.address !== undefined && data.city !== undefined && data.district !== undefined && data.UF !== undefined) {
                    return true;
                } 
            }
        }

        return false;
    }

    validateStep2 = () => {
        const {data} = this.state;
        
        if (data.phone !== undefined ||  data.phone2 !== undefined) {
            if (data.phone !== "" ||  data.phone2 !== "") {
                return true;
            }
        } 

        if (data.phone !== undefined &&  data.phone2 !== undefined) {
            if (data.phone !== "" &&  data.phone2 !== "") {
                return true;
            }
        }

        return false;
    }

    notify = (error) =>  {
        var options = {};
    
        options = {
          place: "tr",
          message: (<div>{error}</div>),
          type: "danger",
          icon: "nc-icon nc-bell-55",
          autoDismiss: 10
        };
        
        this.refs.notificationAlert.notificationAlert(options);
    }

    render() {
        const { data } = this.state;

        return (
            <div style={{background: '#f9f9f9', height: '100%', width: '100%', position: 'absolute'}}>
            <div className="container" style={{paddingTop: 25}}>
                <NotificationAlert ref="notificationAlert" />
                <Row>
                    <Col xs={12}>
                        <Card className="card-user">
                            <CardHeader>
                                <CardTitle>Adicionar Estabelecimento</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <form onChange={this.onFieldChanged} onSubmit={this.setData}>
                                    <FormInputs
                                        ncols={["col-md-5 pr-1", "col-md-3 px-1", "col-md-4 pl-1"]}
                                        proprieties={[
                                        {
                                            label: "CNPJ*",
                                            inputProps: {
                                                name: 'CNPJ',
                                                type: "text",
                                                onChange: this.cnpjMask,
                                                defaultValue: "",
                                                maxLength: 18
                                            }
                                        },
                                        {
                                            label: "Nome*",
                                            inputProps: {
                                                type: "text",
                                                name: "name",
                                                defaultValue: ""
                                            }
                                        },
                                        {
                                            label: "E-mail*",
                                            inputProps: {
                                                type: "email",
                                                name: "email",
                                                defaultValue: ""
                                            }
                                        }
                                        ]}
                                    />
                                    <FormInputs
                                        ncols={["col-md-3 pr-1", "col-md-3 px-1", "col-md-6 pl-1"]}
                                        proprieties={[
                                        {
                                            label: "Contato 01*",
                                            inputProps: {
                                                type: "number",
                                                name: "phone",
                                                defaultValue: ""
                                            }
                                        },
                                        {
                                            label: "Contato 02",
                                            inputProps: {
                                                type: "number",
                                                name: "phone2",
                                                defaultValue: ""
                                            }
                                        },
                                        {
                                            label: "Website",
                                            inputProps: {
                                                type: "text",
                                                name: "website",
                                                defaultValue: ""
                                            }
                                        }
                                        ]}
                                    />
                                    <FormInputs
                                        ncols={["col-md-4 pr-1", "col-md-3 px-1", "col-md-5 pl-1"]}
                                        proprieties={[
                                            {
                                                label: "CEP*",
                                                inputProps: {
                                                    name: 'CEP',
                                                    type: "text",
                                                    onChange: this.cepMask,
                                                    maxLength: 9,
                                                    defaultValue: ""
                                                }
                                            },
                                            {
                                                label: "N°*",
                                                inputProps: {
                                                    name: 'number',
                                                    type: "text",
                                                    defaultValue: ""
                                                }
                                            },
                                            {
                                                label: "Rua*",
                                                inputProps: {
                                                    disabled: true,
                                                    type: "text",
                                                    name: 'address',
                                                    defaultValue: data.address
                                                }
                                            }
                                        ]}
                                    />
                                    <FormInputs
                                        ncols={["col-md-4 pr-1", "col-md-4 px-1", "col-md-4 pl-1"]}
                                        proprieties={[
                                            {
                                                label: "Cidade*",
                                                inputProps: {
                                                    disabled: true,
                                                    name: 'city',
                                                    type: "text",
                                                    defaultValue: data.city
                                                }
                                            },
                                            {
                                                label: "Bairro*",
                                                inputProps: {
                                                    disabled: true,
                                                    name: 'district',
                                                    type: "text",
                                                    defaultValue: data.district
                                                }
                                            },
                                            {
                                                label: "Estado*",
                                                inputProps: {
                                                    disabled: true,
                                                    name: 'UF',
                                                    type: "text",
                                                    defaultValue: data.UF
                                                }
                                            }
                                        ]}
                                    />
                                    <Row>
                                        <div className="update ml-auto mr-auto">
                                            <Button color="primary" round >Cadastrar</Button>
                                        </div>
                                    </Row>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div></div>
        );
    }
}

const mapStateToProps = store => ({
    userInfo: store.userInfo.userInfo
});
  
const mapDispatchToProps = dispatch =>
  bindActionCreators({ saveUserInfo, clearUserInfo }, dispatch);

export default connect (mapStateToProps, mapDispatchToProps)(Subscribe);

