/**
 *  DemandTab.jsx
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 09/01/2019.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import React, { Component } from "react";

import { Card, CardBody, Row, Col, TabContent,  TabPane, Nav,  NavItem, NavLink } from "reactstrap";

import PendingPayment from "./Tables/PendingPayment";
import PendingConfirm from "./Tables/PendingConfirm";
import PendingDelivery from "./Tables/PendingDelivery";
import DemandHistoric from "./Tables/DemandsHistoric";

import { HeaderFilter } from "./Tables/HeaderFilter";

import { connect } from 'react-redux';
import classnames from 'classnames';

require('../../assets/css/style.css');

var utils = require("../../utils/index.js");
var firebase = require("../../firebase");

class DemandTab extends Component {

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
      initialDate: null,
      finalDate: null,
      historicData: []
    };
  }

  componentWillMount() {
    this.getDate();
  }

  componentDidMount(){
    document.title =  this.props.storeInfo.name + " | Orçamentos";
    utils.verifyUser(); // Verifica se há usuário logado
    this.verifyStore(); // Verifica se há uma loja selecionada
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  verifyStore = () => {
    const { history, storeInfo} = this.props;

    if (storeInfo === undefined || storeInfo === ""  || storeInfo === null){
      history.push('/stores');
    } 
  }

  reload = () => {
    this.setState({ historicData: [] });
    this.loadData(this.state.initialDate, this.state.finalDate);
  }

  /**
   * Metodo para buscar data inicial do filtro
   */
  getDate = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    const initialDate = new Date(currentYear, currentMonth, 1);
    const finalDate = new Date(currentYear, currentMonth + 1, 1);
    const initialEpoch = initialDate.getTime();
    const finalEpoch = finalDate.getTime() - 1;

    this.setState({
      initialDate: initialEpoch,
      finalDate: finalEpoch
    });

    this.loadData(initialEpoch, finalEpoch);
  }

  filter = (initialEpoch, finalEpoch) => {
    this.setState({
      initialDate: initialEpoch,
      finalDate: finalEpoch
    });

    this.clearData();
    this.loadData(initialEpoch, finalEpoch);
  }

  clearData = () => {
    this.setState({ historicData: [] });
  }

  loadData = (initialDate, finalDate) => {
    let historicRows = [];
    
    firebase.database.ref('suppliers/' + this.props.storeInfo.key + '/requests').on('value', function(snapshot) {
      const snaps = snapshot.val();

      for(const key in snaps) {
        const values = snaps[key];

        if (values.status == 4) {

          firebase.database.ref('requests/' + values.requestKey).on('value', function(snapshot) {
              const snap = snapshot.val();
              
              var demands = new Object();
              demands["key"] = key;
              demands["actions"] = key;
              demands["userName"] = values.userName;
              demands["createdAt"] = values.createdAt;
              demands["deliveryMode"] = values.deliveryMode;
              demands["status"] = values.status;
              demands["dateDelivery"] = values.dateDelivery;
              demands["orderCode"] = values.orderCode;
              
              var demands2 = new Object();
              demands2["discount"] = snap.discount;
              demands2["fare"] = snap.fare;
              demands2["hasReceipt"] = snap.hasReceipt;
              demands2["orderItems"] = snap.orderItems;
              demands2["value"] = snap.value;
              demands2["projectAddress"] = snap.projectAddress;
              demands2["projectCity"] = snap.projectCity;
              demands2["projectState"] = snap.projectState;

              if (values.dateDelivery >= initialDate && values.dateDelivery <= finalDate){
                historicRows = [...historicRows, Object.assign(demands, demands2)];
              }
          });
        }
      }
    });

    this.setState({ historicData: historicRows })
  }

  render() {
    const { initialDate, finalDate } = this.state;
    return (
        <div className="content">
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                <div>
                    <Nav tabs>
                      
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '1' })}
                          onClick={() => { this.toggle('1'); }}
                        >
                          Pagamento Pendente
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '2' })}
                          onClick={() => { this.toggle('2'); }}
                        >
                          Aguardando Confirmação
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '3' })}
                          onClick={() => { this.toggle('3'); }}
                        >
                          Entrega Pendente
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '4' })}
                          onClick={() => { this.toggle('4'); }}
                        >
                          Histórico
                        </NavLink>
                      </NavItem>
                      
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                      
                      <TabPane tabId="1">
                        <Row>
                          <Col sm="12">
                            <br></br>
                            <PendingPayment />
                          </Col>
                        </Row>
                      </TabPane>

                      <TabPane tabId="2">
                        <Row>
                          <Col sm="12">
                            <br></br>
                            <PendingConfirm />
                          </Col>
                        </Row>
                      </TabPane>

                      <TabPane tabId="3">
                        <Row>
                          <Col sm="12">
                            <br></br>
                            <PendingDelivery />
                          </Col>
                        </Row>
                      </TabPane>

                      <TabPane tabId="4">
                        <Row>
                          <Col sm="12">
                            <HeaderFilter reload={this.reload} filter={this.filter} initialEpoch={initialDate} finalEpoch={finalDate} />
                          </Col>
                        </Row>
                        
                        <Row>
                          <Col sm="12">
                            <DemandHistoric data={this.state.historicData} />
                          </Col>
                        </Row>
                      </TabPane>

                    </TabContent>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
    );
  }
}
const mapStateToProps = store => ({
  storeInfo: store.storeInfo.storeInfo
});

export default connect(mapStateToProps)(DemandTab);
