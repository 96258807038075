/**
 *  PendingPayment.jsx
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 09/01/2019.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import React, { Component } from "react";
import {
    DataTypeProvider,
    SortingState,
    IntegratedSorting,
    PagingState,
    IntegratedPaging,
    SearchState, 
    IntegratedFiltering,
} from '@devexpress/dx-react-grid';

import { 
    Grid, 
    Table, 
    TableHeaderRow,
    PagingPanel,
    Toolbar,
    SearchPanel,
} from '@devexpress/dx-react-grid-bootstrap4';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionsFormatter from "../ButtonActions/ActionsButtons";
import { savePendingPayment, clearDemands } from '../../../actions/demandsPayment';

require('../../../assets/css/style.css')

var firebase = require("../../../firebase");
var utils = require("../../../utils/index.js");

const ActionsButtons = (props) => { 
    return (
        <DataTypeProvider
        formatterComponent={ActionsFormatter}
        {...props}
        />
    )
};

class PendingPayment extends Component {

    constructor(props) {
        super(props);

        this.state = {
            columns: [
                { name: 'orderCode', title: '#Código' },
                { name: 'userName', title: 'Cliente' },
                { name: 'deliveryMode', title: 'Tipo de Entrega', 
                    getCellValue: row => (
                        row.deliveryMode == 1 ? <span className="badge badge-warning p-1">Retirar na loja</span> : <span className="badge badge-primary p-1">Entregar na Obra</span>
                    )
                },
                { name: 'dateDelivery', title: 'Entregar até', 
                    getCellValue: row => (utils.dateFormatter(row.dateDelivery))
                },
                { name: 'actions', title: 'Ações' },
            ],
            actionColumn: ['actions'],
            searchValue: '',
        };

        this.changeSearchValue = value => this.setState({ searchValue: value });
    }

    componentWillMount() {
        const { savePendingPayment, clearDemands, storeInfo} = this.props;
        
        firebase.database.ref('suppliers/' + storeInfo.key + '/requests').on('value', function(snapshot) {
            const snaps = snapshot.val();
            var all = [];

            for(const key in snaps) {
                const values = snaps[key];

                if (values.status == 1) {

                    firebase.database.ref('requests/' + values.requestKey).once('value', function(snapshot) {
                        const snap = snapshot.val();
                        
                        var pendingPayment = new Object();
                        pendingPayment["key"] = key;
                        pendingPayment["actions"] = key;
                        pendingPayment["userName"] = values.userName;
                        pendingPayment["createdAt"] = values.createdAt;
                        pendingPayment["deliveryMode"] = values.deliveryMode;
                        pendingPayment["status"] = values.status;
                        pendingPayment["dateDelivery"] = values.dateDelivery;
                        pendingPayment["orderCode"] = values.orderCode;
                        
                        var pendingPayment2 = new Object();
                        pendingPayment2["discount"] = snap.discount;
                        pendingPayment2["fare"] = snap.fare;
                        pendingPayment2["hasReceipt"] = snap.hasReceipt;
                        pendingPayment2["orderItems"] = snap.orderItems;
                        pendingPayment2["value"] = snap.value;
                        pendingPayment2["projectAddress"] = snap.projectAddress;
                        pendingPayment2["projectCity"] = snap.projectCity;
                        pendingPayment2["projectState"] = snap.projectState;

                        //Esta solução apresentou falhas de carregamento.
                        //all.push(Object.assign(pendingPayment, pendingPayment2));

                        all = [...all, Object.assign(pendingPayment, pendingPayment2)];

                        // Salva a lista de pagamentos pendentes no Redux
                        savePendingPayment(all);
                    });
                }
            }
        });
    }

    render() {
        const { pendingPayment } = this.props;
        const { columns, actionColumn, searchValue} = this.state;
        
        return (

            <Grid
                rows={pendingPayment}
                columns={columns}
            >
                <ActionsButtons
                    for={actionColumn}
                />

                <PagingState
                    defaultCurrentPage={0}
                    defaultPageSize={25}
                />
                <IntegratedPaging />
                <PagingPanel
                    pageSizes={[10, 25, 50, 0]}
                />

                <SortingState
                    defaultSorting={[{ columnName: 'dateEnd', direction: 'asc' }]}
                />
                <IntegratedSorting />

                <SearchState
                    value={searchValue}
                    onValueChange={this.changeSearchValue}
                />
                <IntegratedFiltering />
                <Toolbar />
                <SearchPanel/>

                <Table />
                <TableHeaderRow showSortingControls />
            </Grid>
        );
    }
}

const mapStateToProps = store => ({
    storeInfo: store.storeInfo.storeInfo,
    pendingPayment: store.pendingPayment.pendingPayment
});

const mapDispatchToProps = dispatch =>
    bindActionCreators({ savePendingPayment, clearDemands }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PendingPayment);