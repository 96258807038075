/**
 *  EstimateModal.jsx
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 06/01/2019.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import React, { Component } from "react";
import { 
    Button, 
    Modal, 
    ModalBody, 
    ModalHeader, 
    ModalFooter, 
    MDBCard, 
    MDBCardBody, 
    MDBCardHeader, 
    MDBRow, 
    MDBCol
} from 'mdbreact';

import EstimateModalTable from "./EstimateModalTable";
import { connect } from 'react-redux';

require('../../../assets/css/style.css');

var firebase = require("../../../firebase");
var utils = require("../../../utils/index.js");

export class EstimateModal extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            dataItens: [],
            totalItens: 0,
            discount: 0,
            fare: 0,
            minimumFare: -1,
            total: 0,
            order: {}
        };
    }

    componentWillMount(){
        const { Orderkey  } = this.props;
        var order = {}

        for (var key in this.props.ordersOpened) {
            if (this.props.ordersOpened.hasOwnProperty(key)) {
                if (this.props.ordersOpened[key].key == Orderkey) {
                    order = this.props.ordersOpened[key];
                }
            }
        }

        for (var key in this.props.ordersWaiting) {
            if (this.props.ordersWaiting.hasOwnProperty(key)) {
                if (this.props.ordersWaiting[key].key == Orderkey) {
                    order = this.props.ordersWaiting[key];
                }
            }
        }

        if (Object.keys(order).length === 0) {
            order = this.searchDataOrders(); // Caso não tenha informado um order
        }

        this.setState({ order: order });
    }

    searchDataOrders = () => {
        const { Orderkey  } = this.props;
        var order = {}

        firebase.database.ref('suppliers/' + this.props.storeInfo.key + '/orders/' + Orderkey).on('value', function(snapshot) {
            
            const snap = snapshot.val();
            var orders = new Object();
        
            orders["key"] = Orderkey;
            orders["clientName"] = snap.clientName;
            orders["createdAt"] = snap.createdAt;
            orders["deliveryMode"] = snap.deliveryMode;
            orders["projectName"] = snap.projectName;
            orders["projectType"] = snap.projectType;
            orders["status"] = snap.status;
            orders["dateEnd"] = snap.dateEnd;
            orders["dateDelivery"] = snap.dateDelivery;
            orders["orderCode"] = snap.orderCode;
            orders["offer"] = snap.offer ? snap.offer : [];
            orders["orderItems"] = snap.orderItems;
            orders["projectAddress"] = snap.projectAddress;
            orders["projectCity"] = snap.projectCity;
            orders["projectState"] = snap.projectState;
            
            order = orders;
        });

        return order
    }

    estimateTotal = (total, values) => {
        this.setState({
            totalItens: total,
            total: (total + this.state.fare) - this.state.discount,
            dataItens: values
        })
    }

    /**
     * (Passo 01) - Válida os dados para enviarem para o banco de dados
     */
    onClickValidate = () => {
        var orderItems = this.state.dataItens
        var step1 = false;
        
        for (const key in orderItems) {
            if (orderItems.hasOwnProperty(key)) {
                if (orderItems[key].quantity == null || orderItems[key].unitaryPrice == null) {
                    delete orderItems[key];
                    step1 = true;
                } else {
                    step1 = true;
                }
            } 
        }
        this.sendEstimate(step1);
    }

    /**
     * (Passo 02) - Finaliza o orçamento e armazena no banco de dados
     */
    sendEstimate = (step1) => {
        var orderItems = this.state.dataItens
        var step2 = false;
        var step3 = false;

        if (this.state.total == 0 ||this.state.total > 0){
            step3 = true;
        }

        for (const key in orderItems) {
            if (orderItems.hasOwnProperty(key)) {
                step2 = true;
            } 
        }

        if (step1 && step2) {
            if (step3) {
                firebase.database.ref('suppliers/' + this.props.storeInfo.key + '/orders/' + this.props.Orderkey + '/offer').set({
                    discount: this.state.discount,
                    fare: this.state.fare,
                    minimumFare: this.state.minimumFare,
                    orderItems,
                    totalValue: this.state.total 
                }).then((success) => {
                    const { toggle } = this.props;
                    toggle();
                    alert('Orçamento enviado com sucesso :)')
                }).catch((error) => {
                    alert(error.message);
                });
            } else {
                alert("Impossivel enviar orçamentos com total negativo!");
            }
        } else {
            alert("Oops... Para concluir é necessário informar ao menos um item!");
        }
    }

    onFieldChangedDiscount = (e) => {
        e.target.value = this.currencyFormatter(e.target.value);
        this.setState({
            discount: this.centsFormatter(e.target.value),
            total: (this.state.fare + this.state.totalItens) - this.centsFormatter(e.target.value)
        })
    }

    onFieldChangedFare = (e) => {
        e.target.value = this.currencyFormatter(e.target.value);
        this.setState({
            fare: this.centsFormatter(e.target.value),
            total: (this.state.totalItens + this.centsFormatter(e.target.value)) - this.state.discount
        })
    }

    onFieldChangedMinimumFare = (e) => {
        e.target.value = this.currencyFormatter(e.target.value);
        this.setState({
            minimumFare: this.centsFormatter(e.target.value)
        })
    }

    closeModal = () => {
        const { toggle } = this.props;
        toggle();
    }

    refuseEstimate = () => {
        
        if (window.confirm('Essa ação é irreversível. Deseja mesmo recusar?')) {
            const { toggle } = this.props;
            toggle();

            firebase.database.ref('suppliers/' + this.props.storeInfo.key + '/orders/' + this.props.Orderkey).update({
                status: 3
            });
        }
    }

    centsFormatter = (value) => {
        let orderValueFormatted = this.currencyFormatter(value);
        let orderValue = orderValueFormatted.replace( /^\D+/g, '');
        orderValue = orderValue.replace(',', '');
    
        return  parseInt(orderValue);
    }

    currencyFormatter = (value) => {
        if (value == '' || value == null || value == undefined){
            value = 0;
        }
        value = value.toString().replace( /^\D+/g, '');
        value = value.replace(',', '');
        value = parseInt(value).toString();
        
        if (value.length == 0 || value == 0){
            value = '0,00';
        } else if (value.length == 1){
            value = '0,0' + value
        } else if (value.length == 2){
            value = '0,' + value
        } else {
            let position = value.length -2;
            value = value.slice(0, position) + ',' + value.slice(position);
        }
        value = "R$ " + value;

        return value;
    }

    currencyTotal = () => {
        return (
            <small>{this.state.order.offer != null ? utils.currencyFormatter(this.state.order.offer.totalValue) : utils.currencyFormatter(this.state.total)}</small>
        );
    }

    getButtons = () => {
        if (this.state.order.status != 1 ) {
            return <Button color="danger" onClick={this.closeModal}>Fechar</Button>;
        } else {
            return (
                <div>
                    <Button color="danger" className="btn-outline mr-2" onClick={this.refuseEstimate}>Recusar</Button> 
                    <Button color="info" onClick={this.onClickValidate}>Enviar</Button>
                </div>
            );
        }  
        
    }

    deliveryMode = () => {
        if (this.state.order.deliveryMode == 0 ) {
            if (this.state.order.offer) {
                return (
                    <div>
                        <strong>Valor do Frete: </strong> {utils.currencyFormatter(this.state.order.offer.fare)}
                    </div>
                );
            } else {
                return (
                    <div>
                        <strong>Valor do Frete: </strong><input onChange={this.onFieldChangedFare} placeholder="0,00"/>
                    </div>);
            }
        }
    }

    minimumFareMode = () => {
        if (this.state.order.deliveryMode == 0 ) {
            if (this.state.order.offer) {
                return (
                    <div>
                        {(this.state.order.offer.minimumFare && this.state.order.offer.minimumFare >= 0) ?
                            (<div><strong>Frete grátis à partir de: </strong> {utils.currencyFormatter(this.state.order.offer.minimumFare)}</div>)
                            : ""}
                    </div>
                );
            } else {
                return (
                    <div>
                        <strong>Frete grátis à partir de: </strong><input onChange={this.onFieldChangedMinimumFare} placeholder="0,00"/>
                    </div>
                );
            }
        }
    }

    deliveryLocation = () => {
        if (this.state.order.deliveryMode == 0){
            return (
                <MDBCol>
                    <h5 className="title-client-h-b"> Endereço para Entrega: {this.state.order.projectAddress} - {this.state.order.projectCity}/{this.state.order.projectState}</h5>
                </MDBCol>
            );
        } 
    }
    
    render() {
        const { show, toggle } = this.props;
        
        return (
            <Modal isOpen={show} size="lg">

                <ModalHeader toggle={toggle} className="bg-info text-white">Enviar orçamento para: <small>#Código {this.state.order.orderCode}</small></ModalHeader>

                <ModalBody>
                    <MDBRow>
                        <MDBCol>
                            <MDBCard border="dark" className="m-3">
                                <MDBCardHeader className="title-h-b">
                                    Dados do orçamento: 
                                    {this.state.order.deliveryMode == 1 ? <span className="bg-white text-warning pl-2 pr-2">Retirar na loja</span> : <span className="bg-white text-primary pl-2 pr-2">Entregar na Obra</span>}
                                </MDBCardHeader>
                                <MDBCardBody className="text-dark data-est">
                                    <MDBRow>
                                        <MDBCol><h5 className="title-client-h-b">Cliente: {this.state.order.clientName}</h5></MDBCol>
                                        <MDBCol><h5 className="title-client-h-b">Orçar até: {utils.dateFormatter(this.state.order.dateEnd)}</h5></MDBCol>
                                        <MDBCol><h5 className="title-client-h-b">Entregar até:  {utils.dateFormatter(this.state.order.dateDelivery)}</h5></MDBCol>
                                        {this.deliveryLocation()}
                                    </MDBRow>
                                </MDBCardBody>
                                <br></br>
                            </MDBCard>
                            <MDBCard className="m-3">
                                <EstimateModalTable Order={this.state.order} total={this.estimateTotal}/>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol className="ml-3">
                            <strong>Desconto: </strong> 
                            {this.state.order.offer ? utils.currencyFormatter(this.state.order.offer.discount) : <input placeholder="0,00" name="discount" onChange={this.onFieldChangedDiscount}/>} <br></br>
                            <small>*O desconto será aplicado apenas para compra de todos os produtos</small>
                        </MDBCol>
                        <MDBCol className="ml-3">{this.deliveryMode()}</MDBCol>
                        <MDBCol className="ml-3">{this.minimumFareMode()}</MDBCol>
                    </MDBRow>
                </ModalBody>
                
                <ModalFooter>
                    <div className="comp-currency">
                        <span className="currency-total">Total: {this.currencyTotal()} </span>
                    </div>
                    {this.getButtons()}
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = store => ({
    ordersOpened: store.ordersOpened.ordersOpened,
    ordersWaiting: store.ordersWaiting.ordersWaiting,
    storeInfo: store.storeInfo.storeInfo
});

export default connect(mapStateToProps)(EstimateModal);