/**
 *  userInfo.js
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 17/12/2018.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import * as actionTypes from './actionTypes';

export const saveUserInfo = value => ({
    type: actionTypes.SAVE_USER_INFO,
    userInfo: value
});

export const clearUserInfo = value => ({
    type: actionTypes.CLEAR_USER_INFO,
    userInfo: []
});