/**
 *  Panel.jsx
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 19/12/2018.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import React, { Component } from "react";

import Stats from "components/Stats/Stats.jsx";
import { Card, CardBody, CardFooter, CardTitle, Row, Col, Alert} from "reactstrap";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clearNotifications } from '../../actions/notifications';

require('../../assets/css/style.css')

var utils = require("../../utils/index.js");
var firebase = require("../../firebase");

class Panel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      statistics: {},
      visible: false
    };
  }

  componentDidMount() {
    document.title =  this.props.storeInfo.name + " | Painel";

    utils.verifyUser(); // Verifica credenciais do usuário
    this.verifyStore(); // Verifica se há uma loja selecionada

    this.getStatistics(); // Atualiza estatisticas do fornecedor
    this.getNotifications(); // Recebe notificações de outros componentes
  }

  onDismiss = () => {
    this.setState({ visible: false });
  }

  getNotifications = () => {
    const { clearNotifications } = this.props;

    if(this.props.notifications != "") {
      this.setState({ visible: true });

      setTimeout(() => {
        this.setState({ visible: false });
        clearNotifications();
      }, 6000);
    }
  }

  verifyStore = () => {
    const { history, storeInfo} = this.props;

    if (storeInfo === undefined || storeInfo === ""  || storeInfo === null){
      history.push('/stores');
    } 
  }

  getStatistics = () => {
    const { storeInfo } = this.props;
    var statistics = storeInfo.statistics ? storeInfo.statistics : {};

    firebase.database.ref('suppliers/' + storeInfo.key + '/statistics').on('value', function(snapshot) {
      
      const snap = snapshot.val();
      var statistic = new Object();

      statistic["revenues"] = snap.revenues;
      statistic["acceptedOrders"] = snap.acceptedOrders;
      statistic["finishedRequests"] = snap.finishedRequests;

      statistics = statistic;
    
    });

    this.setState({ statistics });
  }

  render() {
    return (
      <div className="content">
        <Row>
          <Col xs={12}>
            {this.props.storeInfo.status === 0  ?
              <Alert color="danger" isOpen={true} toggle={this.onDismiss}>
                <strong>Cadastro pendente! Entre em contato com suporte.</strong>
              </Alert> :
              <Alert color="success" isOpen={this.state.visible} toggle={this.onDismiss}>
                <strong>{this.props.notifications}</strong>
              </Alert>
            }
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} md={6} lg={3}>
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs={5} md={4}>
                    <div className="icon-big text-center">
                      <i className="nc-icon nc-badge text-warning" />
                    </div>
                  </Col>
                  <Col xs={7} md={8}>
                    <div className="numbers">
                      <p className="card-category">Administradores</p>
                      <CardTitle tag="p">{this.props.storeInfo.admins ? Object.keys(this.props.storeInfo.admins).length : "0"}</CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <Stats>
                  {[
                    {
                      i: "fas fa-sync-alt",
                      t: "Atualizado agora"
                    }
                  ]}
                </Stats>
              </CardFooter>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={6} lg={3}>
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs={5} md={4}>
                    <div className="icon-big text-center">
                      <i className="nc-icon nc-money-coins text-success" />
                    </div>
                  </Col>
                  <Col xs={7} md={8}>
                    <div className="numbers">
                      <p className="card-category">Faturamento</p>
                      <CardTitle tag="p">{this.state.statistics.revenues ? utils.currencyFormatter(this.state.statistics.revenues) : "R$ 0,00"}</CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <Stats>
                  {[
                    {
                      i: "far fa-calendar",
                      t: "No último mês"
                    }
                  ]}
                </Stats>
              </CardFooter>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={6} lg={3}>
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs={5} md={4}>
                    <div className="icon-big text-center">
                      <i className="nc-icon nc-box text-primary" />
                    </div>
                  </Col>
                  <Col xs={7} md={8}>
                    <div className="numbers">
                      <p className="card-category">Orçamentos Aceitos</p>
                      <CardTitle tag="p">{this.state.statistics.acceptedOrders ? this.state.statistics.acceptedOrders : "0"}</CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <Stats>
                  {[
                    {
                      i: "far fa-clock",
                      t: "Na última hora"
                    }
                  ]}
                </Stats>
              </CardFooter>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={6} lg={3}>
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs={5} md={4}>
                    <div className="icon-big text-center">
                      <i className="nc-icon nc-delivery-fast text-danger" />
                    </div>
                  </Col>
                  <Col xs={7} md={8}>
                    <div className="numbers">
                      <p className="card-category">Pedidos Finalizados</p>
                      <CardTitle tag="p">{this.state.statistics.finishedRequests ? this.state.statistics.finishedRequests : "0"}</CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <Stats>
                  {[
                    {
                      i: "far fa-clock",
                      t: "Na última hora"
                    }
                  ]}
                </Stats>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs={12} className="more-s text-center">
                      <span>Em breve mais estatísticas para você! </span>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
      </div>
    );
  }
}
const mapStateToProps = store => ({
  userInfo: store.userInfo.userInfo,
  storeInfo: store.storeInfo.storeInfo,
  notifications: store.notifications.notifications
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ clearNotifications }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Panel);
