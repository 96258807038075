/**
 *  notifications.js
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 10/01/2018.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import * as actionTypes from '../actions/actionTypes';

const initialState = {
    notifications: ""
};

export const notifications = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SAVE_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.notifications
            };
        case actionTypes.CLEAR_NOTIFICATIONS:
            return {
                ...state,
                notifications: ""
            };
        default:
            return state;
    }
}
