/**
 *  Stats.jsx
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 17/12/2018.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import React, { Component } from "react";
import PropTypes from "prop-types";

class Stats extends Component {
  render() {
    var stats = [];
    for (var i = 0; i < this.props.children.length; i++) {
      stats.push(<i className={this.props.children[i].i} key={i} />);
      stats.push(" " + this.props.children[i].t);
      if (i !== this.props.children.length - 1) {
        stats.push(<br />);
      }
    }
    return <div className="stats">{stats}</div>;
  }
}

Stats.propTypes = {
  children: PropTypes.array
};

export default Stats;
