/**
 *  demandsConfirm.js
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 09/01/2018.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import * as actionTypes from '../actions/actionTypes';

const initialState = {
    pendingConfirm: []
};

export const pendingConfirm = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SAVE_PENDING_CONFIRM:
            return {
                ...state,
                pendingConfirm: action.pendingConfirm
            };
        case actionTypes.CLEAR_DEMANDS:
            return {
                ...state,
                pendingConfirm: []
            };
        default:
            return state;
    }
}
