/**
 *  Footer.jsx
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 17/12/2018.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import React, { Component } from "react";
import { Nav, NavItem} from "react-bootstrap";

class Footer extends Component {
  render() {
    return (
      <footer class="navbar-fixed-bottom footer-login">
        <Nav pullLeft>
          <NavItem >
            <a className="text-white">Ajuda</a>
          </NavItem>
          <NavItem >
            <a className="text-white">Termos de Serviços</a>
          </NavItem>
          <NavItem >
            <a className="text-white">Privacidade</a>
          </NavItem>
        </Nav>
        <Nav pullRight>
          <NavItem >
            <div className="text-white">
              &copy; {new Date().getFullYear()}{" "}
              <a href="http://www.avamobile.com.br" className="text-white">AVA Soft</a>
            </div>
          </NavItem>
        </Nav>
      </footer>
    );
  }
}

export default Footer;
