/**
 *  Register.jsx
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 09/01/2019.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import React, { Component } from "react";

import NotificationAlert from "react-notification-alert";
import Footer from "components/Footer/Footer.jsx";
import { MDBRow, MDBCol, MDBBtn, MDBCard, MDBInput } from "mdbreact";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { saveUserInfo, clearUserInfo } from '../../actions/userInfo';

var firebase = require("../../firebase");
var utils = require("../../utils/index.js");

class Register extends Component {
  
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            email: "",
            password: "",
            visible: false
        };
    }

    componentDidMount = () => {
        document.title = "Ohbra | Registrar-se";
    }

    onSubmitRegister = (e) =>  {
        e.preventDefault()
        const {saveUserInfo, history} = this.props;
        const {email, name, password} = this.state;

        if (email !== "" || name !== "" || password !== "") {
            
            firebase.auth.createUserWithEmailAndPassword(email, password).then((success) => {
                var user = firebase.auth.currentUser;

                if(user) {
                    var userRef = firebase.database.ref('users/' + user.uid);
                    let obj = {
                        createdAt: new Date().getTime(),
                        status: 1,
                        email: email,
                        name: name,
                        permission: 1
                    }

                    userRef.set(obj);
                    saveUserInfo(obj);

                    history.push('/stores');
                }
            });
        } else {
            this.notify("Preencha os dados corretamente!");
        }
    }

    notify = (error) =>  {
        var options = {};

        options = {
        place: "tr",
        message: (
            <div>
            <div>{error}</div>
            </div>
        ),
        type: "danger",
        icon: "nc-icon nc-bell-55",
        autoDismiss: 10
        };
        
        this.refs.notificationAlert.notificationAlert(options);
    }

    onFieldChanged = (e) => {
        this.state[e.target.name] = e.target.value;
    }

    render() {
        return (
            <div className="container-bg">
                <NotificationAlert ref="notificationAlert" />
                
                <MDBCard className="card-image bg-card text-center">
                    <div className="text-white  py-4 px-4 z-depth-4">
                        <div className="text-center">
                            <h3 className="white-text mb-1 font-weight-bold title-logo">
                                <img src={require('../../assets/img/img_logo.png')} className="logo"></img>
                            </h3>

                            <h5 className="text-dark mb-2 sub-logo">Informe os dados abaixo para se cadastrar.</h5>
                        </div>
                            
                        <form onChange={this.onFieldChanged} onSubmit={this.onSubmitRegister}>
                            <MDBInput label="Nome" group name="name" type="text" validate className="text-dark" />
                            <MDBInput label="E-mail" group name="email" type="email" validate className="text-dark" />
                            <MDBInput label="Senha" group name="password" type="password" validate className="text-dark" />

                            <MDBRow className="d-flex align-items-center mb-1">
                                <div className="text-center mb-1 col-md-12 btn-b">
                                <MDBBtn block color="info" rounded type="submit" className="btn-block z-depth-1">Cadastrar</MDBBtn>
                                </div>
                            </MDBRow>
                        </form>

                        <MDBCol md="12">
                            <p className="text-dark text-center">
                                <a href="#/login" className="text-default mt-2">Já tenho conta</a>
                            </p>
                        </MDBCol>
                    </div>
                </MDBCard>

                <Footer />

            </div>
        );
    }
}

const mapStateToProps = store => ({

});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ saveUserInfo, clearUserInfo }, dispatch);

export default connect (mapStateToProps, mapDispatchToProps)(Register);
