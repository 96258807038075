/**
 *  Profile.jsx
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 21/12/2018.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import React, { Component } from "react";

import { Card, CardHeader, CardBody, CardTitle, Button, Row, Col } from "reactstrap";

import FormInputs from "components/FormInputs/FormInputs.jsx";
import NotificationAlert from "react-notification-alert";
import defaultAvatar from "assets/img/default-avatar.png";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { saveStoreInfo, clearStoreInfo } from '../../actions/storeInfo';

var firebase = require("../../firebase");
var utils = require("../../utils/index.js");

class Profile extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      infoTeam: [],
      name: this.props.storeInfo.name,
      email: this.props.storeInfo.email,
      phone: this.props.storeInfo.phone,
      phone2: this.props.storeInfo.phone2,
      website: this.props.storeInfo.website,
      lat: 0,
      lon: 0
    };
  }

  componentWillMount() {
    this.getTeam();
    this.getLocation();
  } 

  componentDidMount() {
    document.title =  this.props.storeInfo.name + " | Perfil";
    utils.verifyUser(); // Verifica credenciais do usuário
    this.verifyStore(); // Verifica se há uma loja selecionada
  }

  verifyStore = () => {
    const { history, storeInfo} = this.props;

    if (storeInfo === undefined || storeInfo === ""  || storeInfo === null){
      history.push('/stores');
    } 
  }

  getLocation = () => {
    firebase.database.ref('supplierLocation/' + this.props.storeInfo.key + '/l').on('value', (snapshot) => {
      const value = snapshot.val();
      this.setState({
        lat: value[0],
        lon: value[1]
      })
    });
  }

  getTeam = () => {
    let infoTeam = [];

    for (let key in this.props.storeInfo.admins) {
      if (this.props.storeInfo.admins.hasOwnProperty(key)) {
            
        firebase.database.ref('users/' + key).once('value').then((snapshot) => {

          let snap = snapshot.val();
          if(snap)
            snap.key = key

          infoTeam.push(snap);
          this.setState({
            infoTeam
          })
        }); 
      }   
    }
  }

  cardTeam = (obj) => {
    return (
      <li>
        <Row>
          <Col xs={2} md={2}>
            <div className="avatar">
              <img src={defaultAvatar} className="img-circle img-no-padding img-responsive" />
            </div>
          </Col>
          <Col xs={7} md={7}>
            {obj.name}
            <br />
            <span className="text-success">
              <small>{obj.name == this.props.userInfo.name ? "Online" : "Offline"}</small>
            </span>
          </Col>
        </Row>
      </li>
    )
  }

  renderArray = (infoTeam) => {
    return (
      infoTeam.map((key, i) => {
        return this.cardTeam(key);
      })
    )
  }

  updateData = (e) => {
    e.preventDefault()

    const name = this.state.name;
    const email = this.state.email;
    const phone = this.state.phone;
    const phone2 = this.state.phone2;
    const website = this.state.website;

    if (name == "" || email == "") {
      this.notify("Os campos Nome e E-mail são obrigatórios.", "danger");
    } else if (phone == "" && phone2 == "") {
      this.notify("É necessário ter ao menos um número para contato.", "danger");
    } else {
      // Atualiza os dados no firebase
      firebase.database.ref('suppliers/' + this.props.storeInfo.key).update({
        name: name,
        email: email,
        phone: phone,
        phone2: phone2,
        website: website,
      }).then((success) => {

        // Atualiza os dados no Redux
        const { saveStoreInfo, storeInfo} = this.props;

        storeInfo.name  = name;
        storeInfo.email  = email;
        storeInfo.phone  = phone;
        storeInfo.phone2  = phone2;
        storeInfo.website  = website
        saveStoreInfo(storeInfo);

        this.notify("Dados atualizados com Sucesso.", "success");
      }).catch((error) => {
        
        this.notify(utils.portugueseMessage(error.message) ? utils.portugueseMessage(error.message) : "Houve uma falha inesperada. Tente Novamente!" , "danger");
      });
    }
  }

  onFieldChanged = (e) => {
    this.state[e.target.name] = e.target.value;
  }

  notify = (msg, type) =>  {
    var options = {};

    options = {
    place: "tr",
    message: (
        <div>
          <div>{msg}</div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7
    };
        
    this.refs.notificationAlert.notificationAlert(options);
  }

  render() {
    return (
      <div className="content">
        <Row>
          <Col md={4} xs={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Localização</CardTitle>
              </CardHeader>
              <CardBody>
                <iframe 
                  src={`https://www.google.com/maps/embed/v1/search?q=${this.state.lat}%2C%20${this.state.lon}&key=AIzaSyCV3bgOEX1eGBUOxAD2Oqyebk-xY2dDUqc`}  
                  style={{ width: "100%", height: "400px"}}  
                  className=""
                />
              </CardBody>
            </Card>
            
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Equipe</CardTitle>
              </CardHeader>
              <CardBody>
                <ul className="list-unstyled team-members">
                  {this.renderArray(this.state.infoTeam)}
                </ul>
              </CardBody>
            </Card>
          </Col>
          <Col md={8} xs={12}>
            <Card className="card-user">
              <CardHeader>
                <CardTitle>Editar Dados</CardTitle>
              </CardHeader>
              <CardBody>
                <NotificationAlert ref="notificationAlert" />
                <form onChange={this.onFieldChanged} onSubmit={this.updateData}>
                  <FormInputs
                    ncols={["col-md-5 pr-1", "col-md-3 px-1", "col-md-4 pl-1"]}
                    proprieties={[
                      {
                        label: "CNPJ",
                        inputProps: {
                          type: "text",
                          disabled: true,
                          defaultValue: this.props.storeInfo.CNPJ
                        }
                      },
                      {
                        label: "Nome",
                        inputProps: {
                          type: "text",
                          name: "name",
                          defaultValue: this.props.storeInfo.name
                        }
                      },
                      {
                        label: "E-mail",
                        inputProps: {
                          type: "email",
                          name: "email",
                          defaultValue: this.props.storeInfo.email
                        }
                      }
                    ]}
                  />
                  <FormInputs
                    ncols={["col-md-3 pr-1", "col-md-3 pr-1", "col-md-6 pl-1"]}
                    proprieties={[
                      {
                        label: "Contato 01",
                        inputProps: {
                          type: "number",
                          name: "phone",
                          defaultValue: this.props.storeInfo.phone
                        }
                      },
                      {
                        label: "Contato 02",
                        inputProps: {
                          type: "number",
                          name: "phone2",
                          defaultValue: this.props.storeInfo.phone2
                        }
                      },
                      {
                        label: "Website",
                        inputProps: {
                          type: "text",
                          name: "website",
                          defaultValue: this.props.storeInfo.website
                        }
                      }
                    ]}
                  />
                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        label: "Endereço",
                        inputProps: {
                          type: "text",
                          disabled: true,
                          defaultValue: this.props.storeInfo.address
                        }
                      }
                    ]}
                  />
                  <FormInputs
                    ncols={["col-md-4 pr-1", "col-md-4 px-1", "col-md-4 pl-1"]}
                    proprieties={[
                      {
                        label: "Cidade",
                        inputProps: {
                          type: "text",
                          disabled: true,
                          defaultValue:  this.props.storeInfo.city
                        }
                      },
                      {
                        label: "Estado",
                        inputProps: {
                          type: "text",
                          disabled: true,
                          defaultValue: this.props.storeInfo.UF
                        }
                      },
                      {
                        label: "CEP",
                        inputProps: {
                          type: "text",
                          disabled: true,
                          defaultValue: this.props.storeInfo.CEP
                        }
                      }
                    ]}
                  />
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button color="primary" round type="submit">Atualizar Dados</Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  userInfo: store.userInfo.userInfo,
  storeInfo: store.storeInfo.storeInfo
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ saveStoreInfo, clearStoreInfo }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Profile);

