/**
 *  ReceiptModal.jsx
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 08/01/2019.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import React, { Component } from "react";
import { 
    Button, 
    Modal, 
    ModalBody, 
    ModalHeader, 
    ModalFooter, 
    MDBCard,
    MDBRow, 
    MDBCol
} from 'mdbreact';

require('../../../assets/css/style.css');

export class ReceiptModal extends Component {
    
    closeModal = () => {
        const { toggle } = this.props;
        toggle();
    }

    render() {
        const { show, toggle } = this.props;
        console.log(this.props.url)
        return (
            <Modal isOpen={show} size="lg">

                <ModalHeader toggle={toggle} className="bg-info text-white">Comprovante do pagamento: <small>#Código {this.props.orderCode}</small></ModalHeader>

                <ModalBody>
                    <MDBRow>
                        <MDBCol>
                            <MDBCard className="m-3">
                               <img className="img-receipt" src={this.props.url} alt="" />
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </ModalBody>
                
                <ModalFooter>
                    <Button color="danger" onClick={this.closeModal}>Fechar</Button>
                </ModalFooter>
            </Modal>
        );
    }
}


export default ReceiptModal;