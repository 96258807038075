/**
 *  firebase/index.jsx
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 10/12/2018.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

var firebase = require('firebase');

var config = {
    apiKey: "AIzaSyCV3bgOEX1eGBUOxAD2Oqyebk-xY2dDUqc",
    authDomain: "ohbra-7f8d9.firebaseapp.com",
    databaseURL: "https://ohbra-7f8d9.firebaseio.com",
    projectId: "ohbra-7f8d9",
    storageBucket: "ohbra-7f8d9.appspot.com",
    messagingSenderId: "780696113811"
};
firebase.initializeApp(config);

module.exports = {
	auth: firebase.auth(),
    database: firebase.database(),
    storage: firebase.storage()
};