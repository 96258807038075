/**
 *  Login.jsx
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 09/01/2019.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import React, { Component } from "react";

import NotificationAlert from "react-notification-alert";
import Footer from "components/Footer/Footer.jsx";
import { MDBRow, MDBCol, MDBBtn, MDBCard, MDBInput } from "mdbreact";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { saveUserInfo, clearUserInfo } from '../../actions/userInfo';

var firebase = require("../../firebase");
var utils = require("../../utils/index.js");

class Login extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      visible: false
    };
  }

  componentWillMount() {
    if(navigator.vendor == "Apple Computer, Inc.") {
      return;
    }

    this.verifyUser();

    firebase.auth.onAuthStateChanged((user) => {
      if (user){
        this.authenticate(user);
      }
    });
  }

  componentDidMount = () => {
    document.title = "Ohbra | Acesse sua conta";
  }

  verifyUser = () => {
    if(firebase.auth.currentUser) {
      this.authenticate(firebase.auth.currentUser);
    }
  }

  authenticate = (user) => {
    const { history, saveUserInfo } = this.props;

    firebase.database.ref('/users/' + user.uid).once('value', (snapshot) => {
      let userInfo = snapshot.val();
      
      if (userInfo.status != 0){
        if (userInfo.permission == 1){
          userInfo['key'] = user.uid;
          saveUserInfo(userInfo);
        } else {
          this.notify('Você ainda não possui permissões para acessar esta área.');
        }
      } else {
        this.notify('Oops! Sua conta está desativada temporariamente.');
      }
    }).then((success) => {

      // Timeout para aguardar os dados persistirem
      setTimeout(() => {
        var user = JSON.parse(localStorage.getItem("persist:root"));
        var logged_in = (user === null ? "" : JSON.parse(user.userInfo));
  
        if(logged_in.userInfo !== "") {
          history.push('/stores');
        }else {
          this.notify('O servidor está lento. Tente novamente!');
        }
      }, 1000);
    })
  }

  onSubmitLogin = (e) =>  {
    e.preventDefault()

    const email = this.state.email;
    const password = this.state.password;

    firebase.auth.signInWithEmailAndPassword(email, password).catch((error) => {
      this.notify(utils.portugueseMessage(error.message));
    });
  }

  notify = (error) =>  {
    var options = {};

    options = {
      place: "tr",
      message: (
        <div>
          <div>{error}</div>
        </div>
      ),
      type: "danger",
      icon: "nc-icon nc-bell-55",
      autoDismiss: 10
    };
    
    this.refs.notificationAlert.notificationAlert(options);
  }

  onFieldChanged = (e) => {
    this.state[e.target.name] = e.target.value;
  }

  render() {
    if(navigator.vendor == "Apple Computer, Inc.") {
      return (
        <div>
          <img src={require('../../assets/img/safari.jpg')} className="logo"></img>
        </div>
      );
    } else {
      return (
        <div className="container-bg">
          <NotificationAlert ref="notificationAlert" />
            
          <MDBCard className="card-image bg-card text-center">
            <div className="text-white  py-4 px-4 z-depth-4">
              <div className="text-center">
                <h3 className="white-text mb-1 font-weight-bold title-logo">
                  <img src={require('../../assets/img/img_logo.png')} className="logo"></img>
                </h3>

                <h5 className="text-dark mb-2 sub-logo">Informe os dados abaixo para ter acesso.</h5>
              </div>
                  
              <form onChange={this.onFieldChanged} onSubmit={this.onSubmitLogin}>
                <MDBInput label="E-mail" group name="email" type="email" validate className="text-dark" />
                <MDBInput label="Senha" group name="password" type="password" validate className="text-dark" />

                <MDBRow className="d-flex align-items-center mb-1">
                  <div className="text-center mb-1 col-md-12 btn-b">
                    <a href="#/forgotten" className="mb-4 d-flex justify-content-end">Esqueceu a senha?</a>
                    <MDBBtn block color="info" rounded type="submit" className="btn-block z-depth-1">Entrar</MDBBtn>
                  </div>
                </MDBRow>
              </form>

              <MDBCol md="12">
                <p className="text-dark text-center">
                  <a href="#/register" className="text-default mt-2">Criar uma conta</a>
                </p>
              </MDBCol>
            </div>
          </MDBCard>

          <Footer />

        </div>
      );
    }
  }
}

const mapStateToProps = store => ({
  userInfo: store.userInfo.userInfo
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ saveUserInfo, clearUserInfo }, dispatch);

export default connect (mapStateToProps, mapDispatchToProps)(Login);
