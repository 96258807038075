/**
 *  DemandModal.jsx
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 08/01/2019.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import React, { Component } from "react";
import { 
    Button, 
    Modal, 
    ModalBody, 
    ModalHeader, 
    ModalFooter, 
    MDBCard, 
    MDBCardBody, 
    MDBCardHeader, 
    MDBRow, 
    MDBCol
} from 'mdbreact';

import { connect } from 'react-redux';
import DemandModalTable from "./DemandModalTable";

var firebase = require("../../../firebase");
var utils = require("../../../utils/index.js");

require('../../../assets/css/style.css');

export class DemandModal extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            demand: {}
        };
    }

    componentWillMount(){
        const { Demandkey  } = this.props;
        var demand = {}

        for (var key in this.props.pendingPayment) {
            if (this.props.pendingPayment.hasOwnProperty(key)) {
                if (this.props.pendingPayment[key].key == Demandkey) {
                    demand = this.props.pendingPayment[key];
                }
            }
        }

        for (var key in this.props.pendingConfirm) {
            if (this.props.pendingConfirm.hasOwnProperty(key)) {
                if (this.props.pendingConfirm[key].key == Demandkey) {
                    demand = this.props.pendingConfirm[key];
                }
            }
        }

        for (var key in this.props.pendingDelivery) {
            if (this.props.pendingDelivery.hasOwnProperty(key)) {
                if (this.props.pendingDelivery[key].key == Demandkey) {
                    demand = this.props.pendingDelivery[key];
                }
            }
        }

        if (Object.keys(demand).length === 0) {
            demand = this.demandHistoric(); 
        }

        this.setState({ demand: demand });
    }

    demandHistoric = () => {
        var demand = {}
        
        firebase.database.ref('suppliers/' + this.props.storeInfo.key + '/requests').on('value', function(snapshot) {
            const snaps = snapshot.val();
        
            for(const key in snaps) {
                const values = snaps[key];
        
                if (values.status == 4) {
        
                    firebase.database.ref('requests/' + values.requestKey).on('value', function(snapshot) {
                        const snap = snapshot.val();
                        
                        var demands = new Object();
                        demands["key"] = key;
                        demands["actions"] = key;
                        demands["userName"] = values.userName;
                        demands["createdAt"] = values.createdAt;
                        demands["deliveryMode"] = values.deliveryMode;
                        demands["status"] = values.status;
                        demands["dateDelivery"] = values.dateDelivery;
                        demands["orderCode"] = values.orderCode;
                        
                        var demands2 = new Object();
                        demands2["discount"] = snap.discount;
                        demands2["fare"] = snap.fare;
                        demands2["hasReceipt"] = snap.hasReceipt;
                        demands2["orderItems"] = snap.orderItems;
                        demands2["value"] = snap.value;
                        demands2["projectAddress"] = snap.projectAddress;
                        demands2["projectCity"] = snap.projectCity;
                        demands2["projectState"] = snap.projectState;
            
                        demand = Object.assign(demands, demands2);
                    });
                }
            }
        });

        return demand;
    }

    closeModal = () => {
        const { toggle } = this.props;
        toggle();
    }

    deliveryLocation = () => {
        if (this.state.demand.deliveryMode == 0){
            return (
                <MDBCol>
                    <h5 className="title-client-h-b"> {this.state.demand.projectAddress} - {this.state.demand.projectCity}/{this.state.demand.projectState}</h5>
                </MDBCol>
            );
        } 
    }

    render() {
        const { show, toggle } = this.props;
        
        return (
            <Modal isOpen={show} size="lg">

                <ModalHeader toggle={toggle} className="bg-info text-white">Códido do Pedido: <small>#{this.state.demand.orderCode} </small></ModalHeader>

                <ModalBody>
                    <MDBRow>
                        <MDBCol>
                            <MDBCard border="dark" className="m-3">
                                <MDBCardHeader className="title-h-b">
                                    Dados do Pedido: 
                                    {this.state.demand.deliveryMode == 1 ? <span className="bg-white text-warning pl-2 pr-2">Retirar na loja</span> : <span className="bg-white text-primary pl-2 pr-2">Entregar na Obra</span>}
                                </MDBCardHeader>
                                <MDBCardBody className="text-dark data-est">
                                    <MDBRow>
                                        <MDBCol><h5 className="title-client-h-b">Cliente: {this.state.demand.userName}</h5></MDBCol>
                                        {this.deliveryLocation()}
                                        <MDBCol><h5 className="title-client-h-b">Entregar até:  {utils.dateFormatter(this.state.demand.dateDelivery)}</h5></MDBCol>
                                    </MDBRow>
                                </MDBCardBody>
                                <br></br>
                            </MDBCard>
                            <MDBCard className="m-3">
                                <DemandModalTable demand = {this.state.demand}/>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol className="ml-3">
                            <strong>Desconto: </strong> 
                            {utils.currencyFormatter(this.state.demand.discount)}<br></br>
                        </MDBCol>
                        <MDBCol className="ml-3">
                            <strong>Valor do Frete: </strong> {utils.currencyFormatter(this.state.demand.fare)}
                        </MDBCol>
                    </MDBRow>
                </ModalBody>
                
                <ModalFooter>
                    <div className="comp-currency">
                        <span className="currency-total">Total:  {utils.currencyFormatter(this.state.demand.value)}</span>
                    </div>
                    <Button color="danger" onClick={this.closeModal}>Fechar</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = store => ({
    storeInfo: store.storeInfo.storeInfo,
    pendingPayment: store.pendingPayment.pendingPayment,
    pendingConfirm: store.pendingConfirm.pendingConfirm,
    pendingDelivery: store.pendingDelivery.pendingDelivery
});

export default connect(mapStateToProps)(DemandModal);