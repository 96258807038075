/**
 *  User.jsx
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 21/12/2018.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import React, { Component } from "react";

import FormInputs from "components/FormInputs/FormInputs.jsx";
import NotificationAlert from "react-notification-alert";
import { Navbar, Container, Card, CardHeader, CardBody, CardTitle, Button, Row, Col } from "reactstrap";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { saveUserInfo, clearUserInfo } from '../../actions/userInfo';

require("assets/css/style.css");

var firebase = require("../../firebase");
var utils = require("../../utils/index.js");

class User extends Component {

  constructor(props) {
    super(props);

    this.state = {
      infoStore: [],
      name: this.props.userInfo.name,
      phone: this.props.userInfo.phone,
    };
  }

  componentDidMount() {
    document.title =  this.props.userInfo.name + " | Perfil";
    utils.verifyUser(); // Verifica credenciais do usuário
  }

  back = () => {
    const { history, storeInfo} = this.props;

    if (storeInfo === undefined || storeInfo === ""  || storeInfo === null){
      history.push('/stores');
    } else {
      history.push('/panel');
    }
  }

  updateData = (e) => {
    e.preventDefault()

    const name = this.state.name;
    const phone = this.state.phone;

    if (name == "" || phone == "") {
      this.notify("Os campos Nome e Contato são obrigatórios.", "danger");
    } else {

      // Atualiza os dados no firebase
      firebase.database.ref('users/' + firebase.auth.currentUser.uid).update({
        name: name,
        phone: phone,
      }).then((success) => {

        // Atualiza os dados no redux
        const { saveUserInfo, userInfo } = this.props;
        userInfo.name = name;
        userInfo.phone = phone;
        saveUserInfo(userInfo);

        this.notify("Dados atualizados com Sucesso.", "success");

      }).catch((error) => {

        this.notify(utils.portugueseMessage(error.message) ? utils.portugueseMessage(error.message) : "Houve uma falha inesperada. Tente Novamente!" , "danger");
      });
    }
  }

  onFieldChanged = (e) => {
    this.state[e.target.name] = e.target.value;
  }

  notify = (msg, type) =>  {
    var options = {};

    options = {
    place: "tr",
    message: (
        <div>
          <div>{msg}</div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7
    };
        
    this.refs.notificationAlert.notificationAlert(options);
  }

  render() {
    return (
      <div className="wrapper">
        <Navbar color={ "white"}>
          <Container fluid>
            <div className="navbar-wrapper">
              <a onClick={this.back} className="back"><i className="fa fa-chevron-left"></i> Voltar</a>
            </div>
          </Container>
        </Navbar>
        <div className="container-fluid">
          <NotificationAlert ref="notificationAlert" />
          <Row>
            <Col xs={12}>
              <Card className="card-user">
                <CardHeader>
                  <CardTitle>Editar Dados</CardTitle>
                </CardHeader>
                <CardBody>
                  <form onChange={this.onFieldChanged} onSubmit={this.updateData}>
                    <FormInputs
                      ncols={["col-md-5 pr-1", "col-md-3 px-1", "col-md-4 pl-1"]}
                      proprieties={[
                        {
                          label: "Email",
                          inputProps: {
                            type: "text",
                            name: "email",
                            disabled: true,
                            defaultValue: this.props.userInfo.email
                          }
                        },
                        {
                          label: "Nome",
                          inputProps: {
                            type: "text",
                            name: "name",
                            defaultValue: this.props.userInfo.name
                          }
                        },
                        {
                          label: "Contato",
                          inputProps: {
                            type: "number",
                            name: "phone",
                            id: "telefone",
                            defaultValue: this.props.userInfo.phone
                          }
                        }
                      ]}
                    />
                    <Row>
                      <div className="update ml-auto mr-auto">
                        <Button type="submit" color="primary" round >Atualizar Dados</Button>
                      </div>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  userInfo: store.userInfo.userInfo
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ saveUserInfo, clearUserInfo }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(User);
