/**
 *  HeaderFilter.jsx
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 09/01/2019.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import React, { Component } from "react";
import { stringfy, getEpoch } from "../../../utils/index.js";

require('../../../assets/css/style.css');

export class HeaderFilter extends Component{
    constructor(props) {
        super(props);

        this.state = {
            initialDate: '',
            initialEpoch: '',
            finalDate: '',
            finalEpoch: ''
        };
    }

    componentWillMount(){
        let initalDateString = stringfy(this.props.initialEpoch);
        let finalDateString = stringfy(this.props.finalEpoch);

        this.setState({
            initialDate: initalDateString,
            initialEpoch: this.props.initialEpoch,
            finalDate: finalDateString,
            finalEpoch: this.props.finalEpoch
        });
    }

    initialDateChange = (event) => {
        let newInitialDate = event.target.value;
        let newInitialEpoch = getEpoch(event.target.value);
        this.setState({initialDate: newInitialDate, initialEpoch: newInitialEpoch });
    }

    finalDateChange = (event) => {
        let newFinalDate = event.target.value;
        let newFinalEpoch = getEpoch(event.target.value);
        newFinalEpoch += 86399999; //adiciona 23:59:59 no epoch
        this.setState({finalDate: newFinalDate, finalEpoch: newFinalEpoch });
    }

    filter = () =>{
        const { initialEpoch, finalEpoch } = this.state;
        this.props.filter(initialEpoch, finalEpoch);
    }

    render(){
        return (
            <div>
                <div className="filter-header">
                    <form>
                        <div className="row">
                            <div className="form-group area-filter col-md-3">
                                <button type="button" className="btn btn-outline-info" onClick={this.props.reload}>
                                    <span className="fa fa-refresh"></span> Refresh
                                </button>
                            </div>
                            <div className="form-group col-md-3">
                                <label forhtml="date">Data inicial:</label>
                                <input id="date" className="form-control" type="date" value={this.state.initialDate} onChange={this.initialDateChange}/>
                            </div>
                            <div className="form-group col-md-3">
                                <label forhtml="date">Data final:</label>
                                <input id="date" className="form-control" type="date" value={this.state.finalDate} onChange={this.finalDateChange}/>
                            </div>
                            <div className="form-group area-filter col-md-3">
                                <button type="button" className="btn btn-outline-info " onClick={this.filter}>
                                    <span className="fa fa-filter"></span> Filtrar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default HeaderFilter;