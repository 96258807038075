/**
 *  ordersWaiting.js
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 08/01/2018.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import * as actionTypes from '../actions/actionTypes';

const initialState = {
    ordersOpened: []
};

export const  ordersOpened = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SAVE_ORDERS_OPENED:
            return {
                ...state,
                ordersOpened: action. ordersOpened
            };
        case actionTypes.CLEAR_ORDERS:
            return {
                ...state,
                ordersOpened: []
            };
        default:
            return state;
    }
}
