/**
 *  PendingConfirm.jsx
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 09/01/2019.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import React, { Component } from "react";

import {
    DataTypeProvider,
    SortingState,
    IntegratedSorting,
    PagingState,
    IntegratedPaging,
    SearchState, 
    IntegratedFiltering,
} from '@devexpress/dx-react-grid';

import { 
    Grid, 
    Table, 
    TableHeaderRow,
    PagingPanel,
    Toolbar,
    SearchPanel,
} from '@devexpress/dx-react-grid-bootstrap4';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReceiptModal from "../Modal/ReceiptModal";
import { savePendingConfirm, clearDemands } from '../../../actions/demandsConfirm';
import ActionsFormatter from "../ButtonActions/ActionsButtons";

require('../../../assets/css/style.css')

var firebase = require("../../../firebase");
var utils = require("../../../utils/index.js");

const ActionsButtons = (props) => { 
    return (
        <DataTypeProvider
        formatterComponent={ActionsFormatter}
        {...props}
        />
    )
};

class PendingConfirm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            columns: [
                { name: 'orderCode', title: '#Código' },
                { name: 'userName', title: 'Cliente' },
                { name: 'deliveryMode', title: 'Tipo de Entrega', 
                    getCellValue: row => (
                        row.deliveryMode == 1 ? <span className="badge badge-warning p-1">Retirar na loja</span> : <span className="badge badge-primary p-1">Entregar na Obra</span>
                    )
                },
                { name: 'hasReceipt', title: 'Comprovante', 
                    getCellValue: row => (
                        row.hasReceipt == false ? "Não recebido" : <a onClick={(evt) => {this.openReceipt(evt, row.actions, row.orderCode)}} className="text-primary"><strong>Ver Comprovante</strong></a>
                    )
                },
                { name: 'dateDelivery', title: 'Entregar até', 
                    getCellValue: row => (utils.dateFormatter(row.dateDelivery))
                },
                { name: 'actions', title: 'Ações' },
            ],
            actionColumn: ['actions'],
            searchValue: '',
            urlImage: "",
            showReceiptModal: false,
            orderCode: "",
        };

        this.changeSearchValue = value => this.setState({ searchValue: value });
    }

    componentWillMount() {
        const { savePendingConfirm, clearDemands, storeInfo} = this.props;

        firebase.database.ref('suppliers/' + storeInfo.key + '/requests').on('value', function(snapshot) {
            const snaps = snapshot.val();
            var all = [];
            
            for(const key in snaps) {
                const values = snaps[key];

                if (values.status == 2) {

                    firebase.database.ref('requests/' + values.requestKey).once('value', function(snapshot) {
                        const snap = snapshot.val();
                        
                        var pendingConfirm = new Object();
                        pendingConfirm["key"] = values.requestKey;
                        pendingConfirm["actions"] = values.requestKey;
                        pendingConfirm["userName"] = values.userName;
                        pendingConfirm["createdAt"] = values.createdAt;
                        pendingConfirm["deliveryMode"] = values.deliveryMode;
                        pendingConfirm["status"] = values.status;
                        pendingConfirm["dateDelivery"] = values.dateDelivery;
                        pendingConfirm["orderCode"] = values.orderCode;
                        
                        var pendingConfirm2 = new Object();
                        pendingConfirm2["discount"] = snap.discount;
                        pendingConfirm2["fare"] = snap.fare;
                        pendingConfirm2["hasReceipt"] = snap.hasReceipt;
                        pendingConfirm2["orderItems"] = snap.orderItems;
                        pendingConfirm2["value"] = snap.value;
                        pendingConfirm2["projectAddress"] = snap.projectAddress;
                        pendingConfirm2["projectCity"] = snap.projectCity;
                        pendingConfirm2["projectState"] = snap.projectState;

                        all = [...all, Object.assign(pendingConfirm, pendingConfirm2)];

                        // Salva a lista de pagamentos pendentes no Redux
                        savePendingConfirm(all);
                    });
                }
            }  
        });
    }

    openReceipt = (evt, key, code) => {

        firebase.storage.ref('receipts/' + key).getDownloadURL().then((url) => {
    
            this.setState({
                orderCode: code,
                urlImage: url,
                showReceiptModal: !this.state.showReceiptModal
            })

        }).catch((error) => {
            alert(error.message);
        });
    }

    toggleModal = () => {
        this.setState({
            showReceiptModal: !this.state.showReceiptModal
        })
    }

    render() {
        const { pendingConfirm } = this.props;
        const { columns, actionColumn, searchValue, showReceiptModal, urlImage} = this.state;
        
        return (
            <div>
                {showReceiptModal === true && <ReceiptModal
                    show={showReceiptModal} 
                    toggle={this.toggleModal}
                    url = {urlImage}
                    orderCode = {this.state.orderCode}
                />}
                <Grid
                    rows={pendingConfirm}
                    columns={columns}
                >
                    <ActionsButtons
                        for={actionColumn}
                    />

                    <PagingState
                        defaultCurrentPage={0}
                        defaultPageSize={25}
                    />
                    <IntegratedPaging />
                    <PagingPanel
                        pageSizes={[10, 25, 50, 0]}
                    />

                    <SortingState
                        defaultSorting={[{ columnName: 'dateEnd', direction: 'asc' }]}
                    />
                    <IntegratedSorting />

                    <SearchState
                        value={searchValue}
                        onValueChange={this.changeSearchValue}
                    />
                    <IntegratedFiltering />
                    <Toolbar />
                    <SearchPanel/>

                    <Table />
                    <TableHeaderRow showSortingControls />
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    storeInfo: store.storeInfo.storeInfo,
    pendingConfirm: store.pendingConfirm.pendingConfirm
});

const mapDispatchToProps = dispatch =>
    bindActionCreators({ savePendingConfirm, clearDemands }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PendingConfirm);
