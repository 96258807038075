/**
 *  ActionsButtons.jsx
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 19/12/2018.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import React, { Component } from "react";
import EstimateModal from "../Modal/EstimateModal";

export class ActionsButtons extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
        }
    }

    toggleModal = () => {
        this.setState({
            showModal: !this.state.showModal
        })
    }

    render() {
        const { showModal } = this.state;
        return (
            <div>
                {showModal === true && <EstimateModal
                    show={showModal} 
                    toggle={this.toggleModal}
                    Orderkey = {this.props.value}
                />}
                
                <button className="mr-2 btn btn-info btn-sm" onClick={this.toggleModal}><i className="fas fa-file-invoice-dollar fa-2x text-white"></i></button>

                {/*<button className="mr-2 btn btn-success btn-sm"><i className="fa fa-whatsapp fa-2x text-white"></i></button>*/}
            </div>
        )
    };
};

export default ActionsButtons;