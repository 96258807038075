/**
 *  DemandModalTable.jsx
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 08/01/2019.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import React, { Component } from "react";
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-bootstrap4';

import { connect } from 'react-redux';

var utils = require("../../../utils/index.js");

require('../../../assets/css/style.css')

class DemandModalTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            values: [],
            columns: [
                { name: 'key', title: '#ID' },
                { name: 'name', title: 'Produto' },
                { name: 'quantity', title: 'Quantidade' },
                { name: 'unitaryPrice', title: 'Preço Unitário', 
                    getCellValue: row => (
                        utils.currencyFormatter(row.unitaryPrice) 
                    )
                },
            ],
            rows: []
        };
    }

    componentDidMount() {
        let rows = [];
        var items = this.props.demand.orderItems;
        
        for (const key in items){

            var demandRows = new Object();

            demandRows["key"] = key;
            demandRows["quantity"] = items[key].quantity;
            demandRows["name"] = items[key].name;
            demandRows["unitaryPrice"] = items[key].unitaryPrice;
            

            rows.push(demandRows);
        }

        this.setState({ rows: rows })
    }
    
    render() {
        const { columns, rows } = this.state;
        return (
            <Grid
                rows={rows}
                columns={columns}
            >

                <Table />
                <TableHeaderRow />
                
            </Grid>
        );
    }
}

const mapStateToProps = store => ({
    storeInfo: store.storeInfo.storeInfo
});

export default connect(mapStateToProps)(DemandModalTable);