/**
 *  userInfo.js
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 10/12/2018.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import * as actionTypes from '../actions/actionTypes';

const initialState = {
    userInfo: ''
};

export const userInfo = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SAVE_USER_INFO:
            return {
                ...state,
                userInfo: action.userInfo
            };
        case actionTypes.CLEAR_USER_INFO:
            return {
                ...state,
                userInfo: ''
            };
        default:
            return state;
    }
}