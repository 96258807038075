/**
 *  demandsDelivery.js
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 08/01/2018.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import * as actionTypes from '../actions/actionTypes';

const initialState = {
    pendingDelivery: []
};

export const pendingDelivery = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SAVE_PENDING_DELIVERY:
            return {
                ...state,
                pendingDelivery: action.pendingDelivery
            };
        case actionTypes.CLEAR_DEMANDS:
            return {
                ...state,
                pendingDelivery: []
            };
        default:
            return state;
    }
}
