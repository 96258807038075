/**
 *  ordersOpened.js
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 08/01/2018.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import * as actionTypes from './actionTypes';

export const saveOrdersOpened = value => ({
    type: actionTypes.SAVE_ORDERS_OPENED,
    ordersOpened: value
});

export const clearOrders = value => ({
    type: actionTypes.CLEAR_ORDERS,
    ordersOpened: []
});