/**
 *  actionTypes.js
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 09/01/2019.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

export const SAVE_USER_INFO = 'SAVE_USER_INFO';
export const CLEAR_USER_INFO = 'CLEAR_USER_INFO';

export const SAVE_STORE_INFO = 'SAVE_STORE_INFO';
export const CLEAR_STORE_INFO = 'CLEAR_STORE_INFO';

export const SAVE_ORDERS_OPENED = 'SAVE_ORDERS_OPENED';
export const SAVE_ORDERS_WAITING = 'SAVE_ORDERS_WAITING';
export const CLEAR_ORDERS = 'CLEAR_ORDERS';

export const SAVE_PENDING_PAYMENT = 'SAVE_PENDING_PAYMENT';
export const SAVE_PENDING_DELIVERY = 'SAVE_PENDING_DELIVERY';
export const SAVE_PENDING_CONFIRM = 'SAVE_PENDING_CONFIRM';
export const CLEAR_DEMANDS = 'CLEAR_DEMANDS';

export const SAVE_NOTIFICATIONS = 'SAVE_NOTIFICATIONS';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';
