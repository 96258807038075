/**
 *  routes/index.jsx
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 19/12/2018.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import Dashboard from "layouts/Dashboard/Dashboard.jsx";
import Login from "../layouts/Account/Login.jsx";
import Register from "../layouts/Account/Register.jsx";
import ResetPassword from "../layouts/Account/ResetPassword.jsx";
import ChooseStore from "../layouts/Store/ChooseStore.jsx";
import Subscribe from "../layouts/Store/Subscribe.jsx";
import User from "../views/User/User.jsx";
var indexRoutes = [
    {
        path: "/login",
        name: "Login",
        component: Login
    },
    {
        path: "/register",
        name: "Register",
        component: Register
    },
    {
        path: "/subscribe",
        name: "Subscribe",
        component: Subscribe
    },
    { 
        path: "/panel", 
        name: "Painel", 
        component: Dashboard
    },
    {
        path: "/estimate",
        name: "Orçamentos",
        component: Dashboard
    },
    {
        path: "/profile",
        name: "Perfil",
        component: Dashboard
    },
    {
        path: "/demand",
        name: "Pedidos",
        component: Dashboard
    },
    {
        path: "/user",
        name: "Usuário",
        component: User
    },
    {
        path: "/forgotten",
        name: "Esqueceu Senha",
        component: ResetPassword
    },
    { 
        path: "/stores", 
        name: "Minhas Lojas", 
        component: ChooseStore
    },
    {
        redirect: true,
        path: "/",
        to: "/login",
        name: "Dashboard" 
    }
];

export default indexRoutes;
