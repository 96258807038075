/**
 *  ordersWaiting.js
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 21/12/2018.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import * as actionTypes from '../actions/actionTypes';

const initialState = {
    ordersWaiting: []
};

export const  ordersWaiting = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SAVE_ORDERS_WAITING:
            return {
                ...state,
                ordersWaiting: action. ordersWaiting
            };
        case actionTypes.CLEAR_ORDERS:
            return {
                ...state,
                ordersWaiting: []
            };
        default:
            return state;
    }
}