/**
 *  EstimateHistoric.jsx
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 08/01/2019.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import React, { Component } from "react";

import {
    DataTypeProvider,
    SortingState,
    IntegratedSorting,
    PagingState,
    IntegratedPaging,
    SearchState, 
    IntegratedFiltering,
} from '@devexpress/dx-react-grid';

import { 
    Grid, 
    Table, 
    TableHeaderRow,
    PagingPanel,
    Toolbar,
    SearchPanel,
} from '@devexpress/dx-react-grid-bootstrap4';

import { connect } from 'react-redux';
import ActionsFormatter from "../ButtonActions/ActionsButtons";

require('../../../assets/css/style.css');

var utils = require("../../../utils/index.js");

const ActionsButtons = (props) => { 
    return (
        <DataTypeProvider
        formatterComponent={ActionsFormatter}
        {...props}
        />
    )
};

class EstimateHistoric extends Component {

    constructor(props) {
        super(props);

        this.state = {
            columns: [
                { name: 'orderCode', title: '#Código' },
                { name: 'clientName', title: 'Cliente' },
                { name: 'deliveryMode', title: 'Tipo de Entrega', 
                    getCellValue: row => (
                        row.deliveryMode == 1 ? <span className="badge badge-warning p-1">Retirar na loja</span> : <span className="badge badge-primary p-1">Entregar na Obra</span>
                    )
                },
                { name: 'period', title: 'Orçar até', 
                    getCellValue: row => (utils.dateFormatter(row.dateEnd))
                },
                { name: 'dateDelivery', title: 'Entregar até', 
                    getCellValue: row => (utils.dateFormatter(row.dateDelivery))
                },
                { name: 'status', title: 'Status' , 
                    getCellValue: row => (
                        this.statusFormatter(row.status)
                    )
                },
                { name: 'actions', title: 'Ações' },
            ],
            actionColumn: ['actions'],
            searchValue: '',
        };

        this.changeSearchValue = value => this.setState({ searchValue: value });
    }

    statusFormatter = (status) => {
        if (status == 3){
            return <span className="badge badge-danger p-1">Rejeitado pelo Fornecedor</span>
        } else if (status == 4) {
            return <span className="badge badge-danger p-1">Rejeitado pelo Usuário</span>
        } else if (status == 5) {
            return <span className="badge badge-success p-1">Aprovado</span>
        }
    }

    render() {
        const { columns, actionColumn, searchValue} = this.state;
        return (
            <Grid
                rows={this.props.data}
                columns={columns}
            >
                <ActionsButtons
                    for={actionColumn}
                />

                <PagingState
                    defaultCurrentPage={0}
                    defaultPageSize={25}
                />
                <IntegratedPaging />
                <PagingPanel
                    pageSizes={[10, 25, 50, 0]}
                />

                <SortingState
                    defaultSorting={[{ columnName: 'dateEnd', direction: 'asc' }]}
                />
                <IntegratedSorting />

                <SearchState
                    value={searchValue}
                    onValueChange={this.changeSearchValue}
                />
                <IntegratedFiltering />
                <Toolbar />
                <SearchPanel/>

                <Table />
                <TableHeaderRow showSortingControls />
            </Grid>
        );
    }
}

const mapStateToProps = store => ({
    storeInfo: store.storeInfo.storeInfo
});

export default connect(mapStateToProps)(EstimateHistoric);