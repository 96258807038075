/**
 *  store/index.js
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 20/12/2018.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import { createStore } from 'redux';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { Reducers } from '../reducers';

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['pendingConfirm', 'pendingDelivery', 'ordersWaiting', 'notifications'] //Lista para não persistir
}

const persistedReducer = persistReducer(persistConfig, Reducers);
const store = createStore(persistedReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
let persistor = persistStore(store);

export { store, persistor };