/**
 *  EstimateModalTable.jsx
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 06/01/2019.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import React, { Component } from "react";
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-bootstrap4';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { saveOrdersOpened, clearOrders } from '../../../actions/ordersOpened';

require('../../../assets/css/style.css')

class EstimateModalTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            values: [],
            columns: [
                { name: 'quantity', title: 'Quantidade',
                    getCellValue: row => (
                        (row.quantity && row.type) ? row.quantity+" "+row.type : row.quantity
                    )
                },
                { name: 'name', title: 'Produto' },
                { name: 'unitaryPrice', title: 'Preço Unitário', 
                    getCellValue: row => (
                        row.unitaryPrice ? this.currencyFormatter(row.unitaryPrice) : <input type="text" placeholder="0,00" onChange={(evt) => {this.onFieldChangedPrice(evt, row)}}/>
                    )
                },
                { name: 'unity', title: 'Unidade',
                    getCellValue: row => (
                        row.unity ? row.unity : <input type="number" min='0' placeholder="0" onChange={(evt) => {this.onFieldChangedQuantify(evt, row)}} onBlur={(evt) => {this.onBlurQuantify(evt, row)}}/>
                    )
                },
            ],
            rows: []
        };
    }

    componentDidMount() {
        let rows = [];
        var offer = this.props.Order.offer == null  ? false : this.props.Order.offer;
        var items = this.props.Order.orderItems;
        
        if (offer){
            for (const key in offer.orderItems){
                var ordersWaiting = new Object();

                ordersWaiting["key"] = key;
                ordersWaiting["unitaryPrice"] = offer.orderItems[key].unitaryPrice;
                ordersWaiting["unity"] = offer.orderItems[key].quantity;

                for (const key in items){
                    if (key == ordersWaiting.key){
                        ordersWaiting["quantity"] = items[key].quantity;
                        ordersWaiting["name"] = items[key].name;
                        ordersWaiting["type"] = items[key].type;
                    }
                }

                rows.push(ordersWaiting);
            }
        } else {
            for (const key in items){
                var ordersOpened = new Object();

                ordersOpened["key"] = key;
                ordersOpened["quantity"] = items[key].quantity;
                ordersOpened["name"] = items[key].name;
                ordersOpened["type"] = items[key].type;

                rows.push(ordersOpened);
            }
        }

        this.setState({
            rows
        })
    }
    
    /**
     * Válida a quantida máxima da unidade que o fornecedor pode oferecer
     */
    onBlurQuantify = (evt, row) => {
        if(parseInt(evt.target.value) > parseInt(row.quantity)){
            evt.target.value = row.quantity

            this.onFieldChangedQuantify(evt, row);
        }
    }

    onFieldChangedPrice = (evt, row) => {
        let values = this.state.values;
        
        if (!values[row.key]) {
            values[row.key] = {
                quantity: null,
                unitaryPrice: null
            }
        }

        evt.target.value = this.currencyFormatter(evt.target.value);
        values[row.key]["unitaryPrice"] = this.centsFormatter(evt.target.value) != 0 ? this.centsFormatter(evt.target.value) : null;
        
        this.setState({values});
        this.updateTotal(); // Atualiza o total do orçamento
    }

    onFieldChangedQuantify = (evt, row) => {
        let values = this.state.values;
        
        if (!values[row.key]) {
            values[row.key] = {
                quantity: null,
                unitaryPrice: null
            }
        }

        values[row.key]["quantity"] = evt.target.value == "" ? null : parseInt(evt.target.value);
        this.setState({values});

        this.updateTotal(); // Atualiza o total do orçamento
    }

    centsFormatter = (value) => {
        let orderValueFormatted = this.currencyFormatter(value);
        let orderValue = orderValueFormatted.replace( /^\D+/g, '');
        orderValue = orderValue.replace(',', '');
    
        return  parseInt(orderValue);
    }

    currencyFormatter = (value) => {
        if (value == '' || value == null || value == undefined){
            value = 0;
        }
        value = value.toString().replace( /^\D+/g, '');
        value = value.replace(',', '');
        value = parseInt(value).toString();
        
        if (value.length == 0 || value == 0){
            value = '0,00';
        } else if (value.length == 1){
            value = '0,0' + value
        } else if (value.length == 2){
            value = '0,' + value
        } else {
            let position = value.length -2;
            value = value.slice(0, position) + ',' + value.slice(position);
        }
        value = "R$ " + value;

        return value;
    }

    updateTotal = () => {
        let values = this.state.values;
        let total = 0;

        for (var key in values) {
            if (values.hasOwnProperty(key)) {
                total += values[key].quantity * values[key].unitaryPrice;
            }
        }

        this.props.total(total, values);
    }

    render() {
        const { columns, rows } = this.state;
        return (
            <Grid
                rows={rows}
                columns={columns}
            >

                <Table />
                <TableHeaderRow />
                
            </Grid>
        );
    }
}

const mapStateToProps = store => ({
    storeInfo: store.storeInfo.storeInfo,
    ordersOpened: store.ordersOpened.ordersOpened,
    ordersWaiting: store.ordersWaiting.ordersWaiting
});

const mapDispatchToProps = dispatch =>
    bindActionCreators({ saveOrdersOpened, clearOrders }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EstimateModalTable);