/**
 *  ResetPassword.jsx
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 20/12/2018.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import React, { Component } from "react";

import NotificationAlert from "react-notification-alert";
import Footer from "components/Footer/Footer.jsx";
import { MDBRow, MDBBtn, MDBCard, MDBInput } from "mdbreact";

require("font-awesome/css/font-awesome.min.css");
require("mdbreact/dist/css/mdb.css");
require("../../assets/css/style.css");

var firebase = require("../../firebase");
var utils = require("../../utils/index.js");

class ResetPassword extends Component {

    constructor(props) {
        super(props);

        this.state = {
            email: "",
            visible: false
        };
    }

    componentDidMount = () => {
        document.title = "Ohbra | Esqueci a senha";
    }

    onSubmitReset = (e) =>  {
        e.preventDefault()
        const email = this.state.email;

        firebase.auth.sendPasswordResetEmail(email).then((success) => {
            this.notify("Uma mensagem foi enviada para sua conta", "success");
        }).catch((error) => {
            this.notify(utils.portugueseMessage(error.message), "danger");
        });
    }

    notify = (msg, type) =>  {
        var options = {};

        options = {
            place: "tr",
            message: (
                <div>
                <div>{msg}</div>
                </div>
            ),
            type: type,
            icon: "nc-icon nc-bell-55",
            autoDismiss: 10
        };
        
        this.refs.notificationAlert.notificationAlert(options);
    }

    onFieldChanged = (e) => {
        this.state[e.target.name] = e.target.value;
    }

    render() {
        return (
            <div className="container-bg">
                <NotificationAlert ref="notificationAlert" />
            
                <MDBCard className="card-image bg-card text-center">
                    <div className="text-white  py-4 px-4 z-depth-4">
                        <div className="text-center">
                            <h3 className="white-text mb-1 font-weight-bold title-logo">
                                <img src={require('../../assets/img/img_logo.png')} className="logo"></img>
                            </h3>
                            
                            <h5 className="text-dark mb-2 sub-logo">Inforne seu e-mail para recuperação.</h5>
                        </div>
                        
                        <form  onChange={this.onFieldChanged} onSubmit={this.onSubmitReset}>
                            <MDBInput label="E-mail" group name="email" type="email" validate className="text-dark" />

                            <MDBRow className="d-flex align-items-center mb-2">
                                <div className="text-center mb-2 col-md-12 btn-b">
                                    <MDBBtn block color="info" rounded type="submit" className="btn-block z-depth-1">Confirmar</MDBBtn>
                                </div>
                            </MDBRow>
                        </form>

                        <p className="version-text">Versão 1.0</p>
                    </div>
                </MDBCard>
                
                <Footer />
            </div>
        );
    }
}

export default ResetPassword;
