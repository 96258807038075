/**
 *  routes/dashboard.jsx
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 19/12/2018.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import Panel from "../views/Panel/Panel";
import EstimateTab from "../views/Estimate/EstimateTab";
import Demand from "../views/Demand/DemandTab.jsx";
import Profile from "../views/Profile/Profile";

const dashboardRoutes = [
  {
    path: "/panel",
    name: "Painel",
    icon: "fa fa-tachometer",
    component: Panel
  },
  {
    path: "/estimate",
    name: "Orçamentos",
    icon: "fa fa-dollar",
    badge: true,
    component: EstimateTab
  },
  {
    path: "/demand",
    name: "Pedidos",
    icon: "fas fa-shipping-fast",
    component: Demand
  },
  {
    path: "/profile",
    name: "Perfil",
    icon: "fas fa-store-alt",
    component: Profile 
  }
];

export default dashboardRoutes;