/**
 *  ordersWaiting.js
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 21/12/2018.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import * as actionTypes from './actionTypes';

export const saveOrdersWaiting = value => ({
    type: actionTypes.SAVE_ORDERS_WAITING,
    ordersWaiting: value
});

export const clearOrders = value => ({
    type: actionTypes.CLEAR_ORDERS,
    ordersWaiting: []
});