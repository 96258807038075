/**
 *  Header.jsx
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 17/12/2018.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Input,
  Badge,
  Breadcrumb,
  BreadcrumbItem
} from "reactstrap";

import { connect } from 'react-redux';
import dashboardRoutes from "routes/dashboard.jsx";

require("../../assets/css/style.css");

var firebase = require("../../firebase");

class Header extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      isOpen: false,
      dropdownOpen: false,
      color: "transparent"
    };
  }
  
  toggle = () => {
    if (this.state.isOpen) {
      this.setState({
        color: "transparent"
      });
    } else {
      this.setState({
        color: "dark"
      });
    }
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  dropdownToggle = (e) => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  getBrand() {
    var name;
    dashboardRoutes.map((prop, key) => {
      if (prop.collapse) {
        prop.views.map((prop, key) => {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
          return null;
        });
      } else {
        if (prop.redirect) {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
        } else {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
        }
      }
      return null;
    });
    return name;
  }

  openSidebar() {
    document.documentElement.classList.toggle("nav-open");
    this.refs.sidebarToggle.classList.toggle("toggled");
  }
  
  updateColor() {
    if (window.innerWidth < 993 && this.state.isOpen) {
      this.setState({
        color: "dark"
      });
    } else {
      this.setState({
        color: "transparent"
      });
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateColor.bind(this));
  }

  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      this.refs.sidebarToggle.classList.toggle("toggled");
    }
  }

  logout() {
    firebase.auth.signOut().then(function() {
    }).catch(function(error) {
      alert(error.message);
    });
  }
  
  render() {
    return (
      <Navbar
        color={
          this.props.location.pathname.indexOf("full-screen-maps") !== -1
            ? "dark"
            : this.state.color
        }
        expand="lg"
        className={
          this.props.location.pathname.indexOf("full-screen-maps") !== -1
            ? "navbar-absolute fixed-top"
            : "navbar-absolute fixed-top " +
              (this.state.color === "transparent" ? "navbar-transparent " : "")
        }
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <button
                type="button"
                ref="sidebarToggle"
                className="navbar-toggler"
                onClick={() => this.openSidebar()}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <Breadcrumb tag="nav" listTag="div">
              <BreadcrumbItem ><a href="#/stores" >Minhas Lojas</a></BreadcrumbItem>
              <BreadcrumbItem><a href="#/profile" >{this.props.storeInfo.name}</a></BreadcrumbItem>
              <BreadcrumbItem className="breadcrumb-panel" tag="span">{this.getBrand()}</BreadcrumbItem>
            </Breadcrumb>
          </div>
          <NavbarToggler onClick={this.toggle}>
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </NavbarToggler>
          <Collapse
            isOpen={this.state.isOpen}
            navbar
            className="justify-content-end"
          >
            <Nav navbar>
              {/*<NavItem>
                <Link to="" className="nav-link">
                  <i className="nc-icon nc-bell-55" />
                  
                  <p>
                    <span className="d-lg-none d-md-block">Notificações</span><Badge color="info">0</Badge>
                  </p>
                </Link>
              </NavItem>*/}
              <Dropdown
                nav
                isOpen={this.state.dropdownOpen}
                toggle={e => this.dropdownToggle(e)}
              >
                <DropdownToggle caret nav>
                  <i className="nc-icon nc-single-02" /> {this.props.userInfo.name}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem href="#/user" >Minha Conta</DropdownItem>
                  <DropdownItem href="#/login" onClick={this.logout}>Sair</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

const mapStateToProps = store => ({
  userInfo: store.userInfo.userInfo,
  storeInfo: store.storeInfo.storeInfo
});
export default connect(mapStateToProps)(Header);
