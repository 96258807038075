/**
 *  ActionsButtons.jsx
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 08/01/2019.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import React, { Component } from "react";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { savePendingConfirm, clearDemands } from '../../../actions/demandsConfirm';

import DemandModal from "../Modal/DemandModal";

var firebase = require("../../../firebase");

export class ActionsButtons extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
        }
    }

    toggleModal = () => {
        this.setState({
            showModal: !this.state.showModal
        })
    }

    /**
     * Tratamento na exibição do botoão de confirmação do pedido
     */
    showButtonConfirm = () => {
        for (var key in this.props.pendingConfirm) {
            if (this.props.pendingConfirm.hasOwnProperty(key)) {
                if (this.props.pendingConfirm[key].key == this.props.value && this.props.pendingConfirm[key].status == 2) {
                    return <button className="mr-2 btn btn-success btn-sm" onClick={this.confirmReceipt}><i className="fas fa-check fa-2x text-white"></i></button>
                } 
            }
        }

        for (var key in this.props.pendingDelivery) {
            if (this.props.pendingDelivery.hasOwnProperty(key)) {
                if (this.props.pendingDelivery[key].key == this.props.value && this.props.pendingDelivery[key].status == 3) {
                    return <button className="mr-2 btn btn-success btn-sm" onClick={this.confirmDelivery}><i className="fas fa-check fa-2x text-white"></i></button>
                }
            }
        }
    }

    confirmDelivery = () => {
        const { clearDemands, storeInfo} = this.props;

        firebase.database.ref('suppliers/' + storeInfo.key + '/requests/' + this.props.value).update({
            status: 4
        }).then((success) => {
            clearDemands();
        });
    }

    confirmReceipt = () => {
        const { clearDemands, storeInfo} = this.props;

        firebase.database.ref('suppliers/' + storeInfo.key + '/requests/' + this.props.value).update({
            status: 3
        }).then((success) => {
            clearDemands();
        });
    }

    render() {
        const { showModal } = this.state;
        return (
            <div>
                {showModal === true && <DemandModal
                    show={showModal} 
                    toggle={this.toggleModal}
                    Demandkey = {this.props.value}
                />}
                
                <button className="mr-2 btn btn-info btn-sm" onClick={this.toggleModal}><i className="fas fa-file-invoice-dollar fa-2x text-white"></i></button>
                {this.showButtonConfirm()}
            </div>
        )
    };
};

const mapStateToProps = store => ({
    storeInfo: store.storeInfo.storeInfo,
    pendingConfirm: store.pendingConfirm.pendingConfirm,
    pendingDelivery: store.pendingDelivery.pendingDelivery
});

const mapDispatchToProps = dispatch =>
    bindActionCreators({ savePendingConfirm, clearDemands }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ActionsButtons);