/**
 *  ChooseStore.jsx
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 21/12/2018.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import React, { Component } from "react";

import { Nav, NavItem} from "react-bootstrap";
import { Row, Col, Navbar, Dropdown, DropdownToggle, DropdownMenu,DropdownItem, Container } from "reactstrap";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { saveStoreInfo, clearStoreInfo } from '../../actions/storeInfo';
import { saveNotifications } from '../../actions/notifications';

require("../../assets/css/style.css");

var firebase = require("../../firebase");
var utils = require("../../utils/index.js");

class ChooseStore extends Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            name: "",
            infoStore: [],
            isOpen: false,
            dropdownOpen: false,
            showModal: false,
        };
    }
    
    componentWillMount() {
        utils.verifyUser(); // Verifica credenciais do usuário
    }

    componentDidMount() {
        document.title = "Ohbra | Minhas Lojas";
        this.getStores(); 
    }

    getStores = () => {
        let infoStore = [{empty: true}];
        for (let key in this.props.userInfo.suppliers) {
            if (this.props.userInfo.suppliers.hasOwnProperty(key)) {
                
                firebase.database.ref('suppliers/' + key).once('value').then((snapshot) => {
                    
                    let snap = snapshot.val();
                    snap.key = key
                    infoStore.push(snap);

                    this.setState({
                        infoStore
                    })
                }); 
            }   
        }
    }

    openModal = () => {
        this.props.history.push('/subscribe');
    }

    cardStore = (obj) => {
        if (obj.empty) {
            return (
                <Col xs={12} md={4}>
                    <a onClick={() => this.openModal()} className="card frontside">
                        <div class="card-body text-center c-store" style={{marginBottom: 27}}>
                            <p><i className="fas fa-plus fa-3x"></i></p>
                            <h4 className="card-title" style={{marginBottom: 27, fontSize: 17}}>Adicionar</h4>
                        </div>
                    </a>
                </Col>
            )
        }
        return (
            <Col xs={12} md={4}>
                <a onClick={() => this.onClickChoose(obj)} className="card frontside">
                    <div class="card-body text-center c-store">
                        <p><i className="fas fa-store-alt fa-3x"></i></p>
                            <h4 className="card-title" style={{fontSize: 17}}>{obj.name}</h4>
                        <p className="card-text">{obj.address} - {obj.city}/{obj.UF}<br></br> CNPJ: {utils.cnpjMask(obj.CNPJ)}</p>
                    </div>
                </a>
            </Col>
        )
    }

    renderArray = (infoStore) => {
        return (
            infoStore.map((key, i) => {
                return this.cardStore(key);
            })
        )
    }

    onClickChoose = (storeSelected) =>  {
        const { history, saveStoreInfo, saveNotifications } = this.props;
        
        var store = new Object();
        store["CEP"] = storeSelected.CEP;
        store["CNPJ"] = storeSelected.CNPJ;
        store["UF"] = storeSelected.UF;
        store["address"] = storeSelected.address;
        store["admins"] = storeSelected.admins;
        store["city"] = storeSelected.city;
        store["email"] = storeSelected.email;
        store["name"] = storeSelected.name;
        store["phone"] = storeSelected.phone;
        store["phone2"] = storeSelected.phone2;
        store["statistics"] = storeSelected.statistics;
        store["website"] = storeSelected.website;
        store["status"] = storeSelected.status;
        store["key"] = storeSelected.key; 

        saveStoreInfo(store);
        saveNotifications("Sessão iniciada com sucesso! Seja bem vindo ao seu painel no Ohbra");
        history.push('/panel');
    }

    dropdownToggle = (e) => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    logout = () => {
        firebase.auth.signOut().then(function() {
        }).catch(function(error) {
            alert(error.message);
        });
    }

    render() {
    return (
        <div className="wrapper">
            <div className="container-header">
                <Navbar color={ "#007eff"} className="navbar-store">
                    <Container fluid>
                        <a className="navbar-brand">Ohbra</a>
                        <Nav navbar>
                            <Dropdown
                                nav
                                isOpen={this.state.dropdownOpen}
                                toggle={e => this.dropdownToggle(e)}
                                className="user-drop"
                            >
                                <DropdownToggle caret nav>
                                <i className="nc-icon nc-single-02" /> {this.props.userInfo.name}
                                </DropdownToggle>
                                <DropdownMenu right>
                                <DropdownItem href="#/user" >Minha Conta</DropdownItem>
                                <DropdownItem href="#/login" onClick={this.logout}>Sair</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            </Nav>
                    </Container>
                </Navbar>
                <div className="container">
                    <h3>Boas-vindas ao Ohbra</h3>
                    <p className="amc-t">Estas são suas lojas cadastradas em nosso sistema,<br></br> 
                    clique no o card desejado para ter acesso ao painel, orçamentos e muito mais.</p>
                    <p className="amc-s">Suas Lojas:</p>
                </div>
            </div>
            <div className="container">
                <Row>
                    {this.props.userInfo.suppliers ?
                        this.renderArray(this.state.infoStore) :
                        <Col xs={12} md={4}>
                            <a onClick={() => this.openModal()} className="card frontside">
                                <div class="card-body text-center c-store" style={{marginBottom: 27}}>
                                    <p><i className="fas fa-plus fa-3x"></i></p>
                                    <h4 className="card-title" style={{marginBottom: 27}}>Adicionar</h4>
                                </div>
                            </a>
                        </Col>
                    }
                </Row>
            </div>
            <a href="#" className="call"><i class="fas fa-question"></i></a>

            <footer class="navbar-fixed-bottom footer-login">
                <Nav pullLeft>
                <NavItem >
                    <a className="text-dark">Ajuda</a>
                </NavItem>
                <NavItem >
                    <a className="text-dark">Termos de Serviços</a>
                </NavItem>
                <NavItem >
                    <a className="text-dark">Privacidade</a>
                </NavItem>
                </Nav>
          </footer>
        </div>
    );
  }
}

const mapStateToProps = store => ({
    userInfo: store.userInfo.userInfo
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ saveNotifications, saveStoreInfo, clearStoreInfo }, dispatch);
  
export default connect(mapStateToProps, mapDispatchToProps)(ChooseStore);

