/**
 *  storeInfo.js
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 17/12/2018.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import * as actionTypes from './actionTypes';

export const saveStoreInfo = value => ({
    type: actionTypes.SAVE_STORE_INFO,
    storeInfo: value
});

export const clearStoreInfo = value => ({
    type: actionTypes.CLEAR_STORE_INFO,
    storeInfo: []
});